$main-menu-item-height: 110px;
$main-menu-width: 120px;
$main-menu-width-lg: 110px;
$main-menu-width-md: 100px;
$main-menu-width-xs: 90px;

$sub-menu-width: 230px;
$sub-menu-width-lg: 230px;
$sub-menu-width-md: 230px;
$sub-menu-width-xs: 230px;

$navbar-height: 100px;
$navbar-height-xs: 70px;
$navbar-height-md: 80px;
$navbar-height-lg: 90px;

$main-margin: 60px;
$main-margin-lg: 50px;
$main-margin-md: 40px;
$main-margin-xs: 15px;

$app-menu-width: 280px;

$main-menu-item-height-mobile: 90px;

$menu-collapse-time: 300ms;
$animation-time-long: 1000ms;
$animation-time-short: 200ms;

$border-radius: 0.1rem;
$border-radius-rounded: 0.75rem;

$theme-color-purple: #922c88;
$theme-color-blue: #145388;
$theme-color-green: #576a3d;
$theme-color-orange: #e2863b;
$theme-color-red: #880a1f;

$info-color: #3195a5;
$success-color: #3e884f;
$warning-color: #b69329;
$error-color: #c43d4b;

/* 20.Alerts */
.alert {
    border-radius: 0;
  }
  
  .alert-primary {
    color: $theme-color-1;
    background-color: rgba($theme-color-1, 0.2);
    border-color: rgba($theme-color-1, 0.1);
  }
  
  .alert-secondary {
    color: $theme-color-2;
    background-color: rgba($theme-color-2, 0.2);
    border-color: rgba($theme-color-2, 0.1);
  }
  
  .alert-success {
    color: $success-color;
    background-color: rgba($success-color, 0.2);
    border-color: rgba($success-color, 0.1);
  }
  
  .alert-info {
    color: $info-color;
    background-color: rgba($info-color, 0.2);
    border-color: rgba($info-color, 0.1);
  }
  
  .alert-warning {
    color: $warning-color;
    background-color: rgba($warning-color, 0.2);
    border-color: rgba($warning-color, 0.1);
  }
  
  .alert-danger {
    color: $error-color;
    background-color: rgba($error-color, 0.2);
    border-color: rgba($error-color, 0.1);
  }
  
  .alert-light {
    color: $light-btn-background;
    background-color: rgba($light-btn-background, 0.2);
    border-color: rgba($light-btn-background, 0.1);
  }
  
  .alert-dark {
    color: $dark-btn-background;
    background-color: rgba($dark-btn-background, 0.2);
    border-color: rgba($dark-btn-background, 0.1);
  }
  
  .alert-dismissible .close {
    text-shadow: initial;
  }
  
  .alert *[data-notify="title"] {
    display: block;
    font-size: 0.9rem;
  }
  
  div[data-notify="container"] {
    padding: 18px;
  }
  
  .notification-container {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;
    width: 320px;
    padding: 0px 15px;
    max-height: calc(100% - 30px);
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .notification {
    box-sizing: border-box;
    padding: 15px 15px 15px 58px;
    border-radius: 2px;
    cursor: pointer;
    font-size: 1em;
    line-height: 1.2em;
    position: relative;
    opacity: 0.9;
    margin-top: 15px;
  }
  
  .notification .title {
    font-size: 1em;
    line-height: 1.2em;
    font-weight: bold;
    margin: 0 0 5px 0;
  }
  
  .notification:hover,
  .notification:focus {
    opacity: 1;
  }
  
  .notification-enter {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
  
  .notification-enter.notification-enter-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);
    transition: all 0.4s;
  }
  
  .notification-leave {
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
  
  .notification-leave.notification-leave-active {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
    transition: all 0.4s;
  }
  

.notification {
    border-radius: $border-radius;
    @include depth(1);
    padding: 25px 25px 25px 25px;
  }
  
  .notification:before {
    content: "";
    display: none;
  }
  
  .notification-primary {
    color: $theme-color-1;
    background-color: $foreground-color;
    border: 1px solid $theme-color-1;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $theme-color-1;
      }
  
      .message {
        color: $primary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $theme-color-1;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  
  .notification-secondary {
    color: $theme-color-2;
    background-color: $foreground-color;
    border: 1px solid $theme-color-2;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $theme-color-2;
      }
  
      .message {
        color: $secondary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $theme-color-2;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  
  .notification-info {
    color: $info-color;
    background-color: $foreground-color;
    border: 1px solid $info-color;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $info-color;
      }
  
      .message {
        color: $secondary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $info-color;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  
  .notification-success {
    color: $success-color;
    background-color: $foreground-color;
    border: 1px solid $success-color;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $success-color;
      }
  
      .message {
        color: $secondary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $success-color;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  
  .notification-warning {
    color: $warning-color;
    background-color: $foreground-color;
    border: 1px solid $warning-color;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $warning-color;
      }
  
      .message {
        color: $secondary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $warning-color;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  
  .notification-error {
    color: $error-color;
    background-color: $foreground-color;
    border: 1px solid $error-color;
    opacity: 1;
  
    .notification-message {
      .title {
        color: $error-color;
      }
  
      .message {
        color: $secondary-color;
      }
    }
  
    &.filled {
      color: $foreground-color;
      background-color: $error-color;
  
      .notification-message {
        .title {
          color: $foreground-color;
        }
  
        .message {
          color: $foreground-color;
        }
      }
    }
  }
  