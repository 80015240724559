.ht_inputFindInteres {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 42px;
  input {
    width: 85%;
    background-color: $blanco;
    border-left: 1px solid $gris4;
    border-top: 1px solid $gris4;
    border-bottom: 1px solid $gris4;
    border-right: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 16px 18px;
    font-size: 16px;
  }
  .language {
    // width: 10%;
    background-color: $blanco;
    // background-color: rebeccapurple;
    padding: 14.5px 18px;
    border-left: none;
    border-top: 1px solid $gris4;
    border-bottom: 1px solid $gris4;
    border-right: none;
    ul {
      margin: 0;
      width: 100%;
      // background-color: aliceblue;
      padding-inline-start: 0px;
      li {
        margin: 0;
        width: 100%;
        // background-color: aqua;
        position: relative;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $negro2;
        &:hover {
          cursor: pointer;
        }
        ul {
          position: absolute;
          background-color: #fff;
          display: none;
          width: 181% !important;
          left: -18px;
          bottom: -40px;
          // bottom: 5px;

          //   padding-inline-start: 0px !important;
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 0;
            width: 100%;
          }
        }
      }
    }
  }
  .btn-find {
    background-color: $azulSecundario;
    border-radius: 0px 8px 8px 0px;
    padding: 13.5px 25px;
    &:hover {
      cursor: pointer;
    }
    img {
      display: none;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: $blanco;
    }
  }
}
.ht__language-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
}
.ht_show-submenu {
  display: block !important;
  // background-color: red;
  box-shadow: 0px 20px 20px rgba(182, 182, 182, 0.3);
}

@media screen and (max-width: 922px) {
  .ht_inputFindInteres {
    margin-top: 20px;
    padding: 0 16px;
    width: auto;
    .btn-find {
      padding: 15.1px 16px;
    }
  }
}
@media screen and (max-width: 774px) {
  .ht_inputFindInteres {
    .language {
      // padding: 12.5px 16px;
      ul {
        li {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    .btn-find {
      padding: 18.2px 16px;
      p {
        display: none;
      }
      img {
        display: block;
      }
    }
  }
}
@media screen and (max-width: 474px) {
  .ht_inputFindInteres {
    input {
      padding: 16px 18px;
    }
  }
}
