.ht__containerGraphics {
  display: flex;
  margin-top: 58px;
}
.ht__containerGraphics-left {
  width: 50%;

  display: flex;
  & > div {
    // width: 50%;
    text-align: start;
  }
}
.ht__containerGraphics-left-size {
  border-right: 1px solid $gris4;
  // padding-right: 30px;
  width: 100%;

  .total-average {
    margin-top: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: $azulPrincipal;
    margin-bottom: 19px;
  }
}

.ht__containerGraphics-bar {
  .bar {
    margin-bottom: 16px;
    & > p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      color: $gris1;
      margin-bottom: 6px;
    }
    .statistics {
      display: flex;
      align-items: center;
      .first-bar {
        width: 90%;
        height: 6px;
        margin-right: 10px;
        & > p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: $negro2;
        }
      }
      .color1 {
        background-color: $amarillo;
      }
      .color2 {
        background-color: $amarillo2;
      }
      .color3 {
        background-color: $amarillo3;
      }
      .color4 {
        background-color: $amarillo4;
      }
      .color5 {
        background-color: $amarillo4;
      }
    }
  }
}
.ht__containerGraphics-left-category {
  display: flex;
  flex-direction: column;
  align-items: center;
  // border-left: 1px solid $gris4;
  padding-left: 40px;
  .title {
    margin-bottom: 30px;
  }
}

.ht__containerGraphics-right {
  width: 50%;
  padding-left: 34px;
  .title {
    margin-bottom: 10px;
  }
}
.ht__containerGraphics-left,
.ht__containerGraphics-right {
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
  }
}
