.ht_interesesScreen {
  padding: 7px 60px 60px 64px;
  // height: 70%;
}
.ht_interesesScreen-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  padding-bottom: 10px;
  color: $azulPrincipal;
  // text-decoration: underline;
}
.ht__interesesScreen-border {
  width: 100%;
  height: 2px;
  background-color: $gris4;
  // margin-bottom: 40px;
  margin-top: -34px;
}
.ht_interesesScreen-tabbar {
  display: flex;
  padding: 32px;
  align-items: center;
  background-color: $blanco;
  // border-bottom: 2px solid $gris4;
  & div:nth-child(1),
  & div:nth-child(2) {
    margin-right: 38px;
  }
  & > div {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 27px;
      color: $gris1;
      padding-bottom: 8px;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__interesesScreen-tabbar-active {
  p {
    font-weight: 500 !important;
    color: $azulPrincipal !important;
    border-bottom: 2px solid $azulPrincipal;
  }
}
.ht_interesesScreen-buscando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 100%;
  margin-top: 8em;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 32px;
    text-align: center;
    color: $azulPrincipal;
    font-family: $righteous;
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 1240px) {
  .ht_interesesScreen {
    padding: 38px 30px 60px 30px;
  }
}
@media screen and (max-width: 922px) {
  .ht__interesesScreen-border {
    display: none;
  }
  .ht_interesesScreen {
    padding: 0 0px;
  }
  .ht_interesesScreen-tabbar {
    background-color: $blanco;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 28px;
    & > div {
      p {
        font-size: 12px;
      }
    }
  }
}
@media screen and (max-width: 474px) {
  .ht__interesesRelacionados {
    .ht_containerListIntereses-header {
      p {
        width: 100%;
        font-size: 14px;
      }
    }
  }
}
