.ht__equipoTrabajo {
  background-color: $blanco;
  border: 1px solid #e6ecef;
  border-radius: 8px;
  //   padding: 32px;
  padding-bottom: 40px;
}
.ht__equipoTrabajo-header {
  padding: 33px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $azulPrincipal;
    font-family: $righteous;
  }
  & > div {
    display: flex;
    align-items: center;
    .btn-invitar {
      background-color: $azulSecundario;
      border-radius: 2px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      font-family: $righteous;
      padding: 10px 16px;
      color: $blanco;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__equipoTrabajo-header-status {
  position: relative;
  margin-right: 18px;
  .btn-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gris3;
    border-radius: 4px;
    padding: 12px 14px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro2;
    }
  }
}

.ht__equipoTrabajo-table-header {
  display: flex;
  background-color: $gris6;
  padding: 13px 31px;
  & > div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
  }
}
.ht__equipoTrabajo-table-header-name {
  width: 32%;
}
.ht__equipoTrabajo-table-header-email {
  width: 32%;
}
.ht__equipoTrabajo-table-header-conection {
  width: 20%;
}
.ht__equipoTrabajo-table-header-status {
  width: 10%;
}
.ht__equipoTrabajo-table-header-action {
  width: 6%;
}

.ht__equipoTrabajo-table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 31px;
  border-bottom: 1px solid $gris4;
}
.ht__equipoTrabajo-table-row-name {
  width: 32%;
  display: flex;
  align-items: center;
  div {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: $amarillo;
    margin-right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 28px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
  }
}
.ht__equipoTrabajo-table-row-email {
  width: 32%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $azulPrincipal;
}
.ht__equipoTrabajo-table-row-conection {
  width: 20%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $gris1;
}
.ht__equipoTrabajo-table-row-status {
  width: 10%;
  overflow: hidden;
  div {
    background-color: $azulPrincipalClaro;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $azulPrincipal;
    text-align: center;
    border-radius: 4px;
    padding: 2px 4px;
    width: max-content;
  }
}
.ht__equipoTrabajo-table-row-action {
  width: 6%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  img {
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__equipoTrabajo-table-row-options-show {
  display: block !important;
}
.ht__equipoTrabajo-table-row-options {
  z-index: 2;
  background-color: $blanco;
  position: absolute;
  border: 1px solid $gris4;
  border-radius: 8px;
  top: 23px;
  left: -50px;
  display: none;
  padding-bottom: 16px;
  padding-top: 5px;
  & > p:nth-child(1) {
    padding: 12px 19px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $gris1;
    border-bottom: 1px solid $gris4;
  }
  & > div {
    padding: 9px 19px;
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $negro1;
    // width: 100%;
    &:hover {
      background-color: $azulPrincipalClaro !important;
      cursor: pointer;
    }
    img {
      margin-right: 9px;
    }
  }
}
.ht__equipoTrabajo-btnInvitar {
  text-align: center;
  background-color: $azulSecundario;
  color: $blanco;
  border-radius: 2px;
  margin: 36px 16px 20px 16px;
  padding: 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: $righteous;
  display: none;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .ht__equipoTrabajo-table-header-name {
    width: 100%;
  }
  .ht__equipoTrabajo-table-header-email {
    display: none;
  }
  .ht__equipoTrabajo-table-header-conection {
    display: none;
  }
  .ht__equipoTrabajo-table-header-status {
    display: none;
  }
  .ht__equipoTrabajo-table-header-action {
    display: none;
  }

  .ht__equipoTrabajo-table-row {
    display: flex;
  }
  .ht__equipoTrabajo-table-row-name {
    width: 65%;
  }
  .ht__equipoTrabajo-table-row-email {
    display: none;
  }
  .ht__equipoTrabajo-table-row-conection {
    display: none;
  }
  .ht__equipoTrabajo-header {
    div {
      display: none;
    }
  }
  .ht__equipoTrabajo-table-row-status {
    position: absolute;
    bottom: 28px;
    left: 95px;
    width: 25%;
  }
  .ht__equipoTrabajo-table-row {
    position: relative;
    padding-bottom: 50px;
  }
  .ht__equipoTrabajo-table-row-options {
    bottom: -165px;
    left: -120px;
  }
  .ht__equipoTrabajo-header {
    & > p {
      font-size: 20px;
    }
  }
  .ht__equipoTrabajo-table-row-name {
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .ht__equipoTrabajo-table-row-status {
    & > div {
      font-size: 12px;
      line-height: 14px;
    }
  }
  .ht__equipoTrabajo {
    margin: 0 16px;
  }
  .ht__equipoTrabajo-btnInvitar {
    display: block;
  }
}
