.ht__engagementComponent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 32px;
}
.ht__engagementComponent-container {
  width: 23%;
  //   padding: 0 10px;
  margin-bottom: 32px;
  //   background-color: aliceblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: $azulPrincipal;
    margin-bottom: 18px;
  }
  & > div {
    // margin: 0 40px;
    border: 7px solid $azulPrincipalClaro;
    height: 190px;
    width: 190px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 50%;
    & > p:nth-child(1) {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31px;
      text-align: center;
      color: $azulPrincipal;
      margin-bottom: 20px;
    }
    & > p:nth-child(2) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $gris2;
      margin-bottom: 2px;
    }
    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $azulPrincipal;
    }
  }
}

@media screen and (max-width: 1475px) {
  .ht__engagementComponent-container {
    width: 33%;
  }
}
@media screen and (max-width: 1220px) {
  .ht__engagementComponent-container {
    & > div {
      width: 150px;
      height: 150px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .ht__engagementComponent-container {
    width: 48%;
  }
}
@media screen and (max-width: 890px) {
  .ht__engagementComponent-container {
    width: 33%;
    & > div {
      & > p:nth-child(1) {
        margin-bottom: 5px;
      }
    }
  }
}
