// Mixin to prefix several properties at once
// @author Hugo Giraudel
// @param {Map} $declarations - Declarations to prefix
// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($declarations, $prefixes: ()) {
    @each $property, $value in $declarations {
      @each $prefix in $prefixes {
        #{'-' + $prefix + '-' + $property}: $value;
      }
      #{$property}: $value;
    }
  }
  
  // Gives a card depth effect.
  // @param {Number} $depth - depth level (between 1 and 5)
  // @link http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality Google Design
  // @requires {function} top-shadow
  // @requires {function} bottom-shadow
  @mixin depth($depth) {
    @if $depth < 1 {
      box-shadow: none;
    } @else if $depth > 5 {
      @warn "Invalid $depth `#{$depth}` for mixin `card`.";
    } @else {
      box-shadow: bottom-shadow($depth), top-shadow($depth);
    }
  }
  
  // Computes a top-shadow for a card effect.
  // @param {Number} $depth - depth level
  // @return {List}
  
  @function top-shadow($depth) {
    $primary-offset: nth($shadowOffsetsTop, $depth) * 1px;
    $blur: nth($shadowBlursTop, $depth) * 4px;
    $color: rgba(black, nth($shadowOpacitiesTop, $depth));
  
    @return 0 $primary-offset $blur $color;
  }
  
  // Computes a bottom-shadow for a card effect.
  // @param {Number} $depth - depth level
  // @return {List}
  @function bottom-shadow($depth) {
    $primary-offset: nth($shadowOffsetsBottom, $depth) * 1px;
    $blur: nth($shadowBlursBottom, $depth) * 5px;
    $color: rgba(black, nth($shadowOpacitiesBottom, $depth));
    @return 0 $primary-offset $blur $color;
  }
  
  @mixin clearfix() {
    &::after {
      display: block;
      content: "";
      clear: both;
    }
  }
  
  
  
  //Responsive Breakpoints
  
  $breakpoints: (
    xxs: 420px,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1440px
  );
  
  
  @mixin respond-above($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
      // Get the breakpoint value.
      $breakpoint-value: map-get(
        $breakpoints,
        $breakpoint
      ); // Write the media query.
      @media (min-width: ($breakpoint-value - 1)) {
        @content;
      }
      // If the breakpoint doesn't exist in the map.
    } @else {
      // Log a warning.
      @warn "Invalid breakpoint: #{$breakpoint}.";
    }
  }
  
  
  @mixin respond-below($breakpoint) {
    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {
      // Get the breakpoint value.
      $breakpoint-value: map-get(
        $breakpoints,
        $breakpoint
      ); // Write the media query.
      @media (max-width: ($breakpoint-value - 1)) {
        @content;
      }
      // If the breakpoint doesn't exist in the map.
    } @else {
      // Log a warning.
      @warn "Invalid breakpoint: #{$breakpoint}.";
    }
  }
  
  @function encodecolor($string) {
      @if type-of($string) == 'color' {
          $hex: str-slice(ie-hex-str($string), 4);
          $string:unquote("#{$hex}");
      }
      $string: '%23' + $string;
      @return $string;
  }
  
  