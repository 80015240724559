// @import url("https://fonts.googleapis.com/css2?family=Jost:wght@400;500&family=Roboto:wght@400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Righteous&display=swap");

// PALETA DE COLORES
$negro1: #061624;
$negro2: #2c3a46;
$gris1: #6e757c;
$gris2: #b2b6b9;
$gris3: #d9dcde;
$gris4: #e6ecef;
$gris5: #f0f4f7;
$gris6: #fafcfc;
$blanco: #fff;
$amarillo: #ff9c01;
$amarillo2: #ffc301;
$amarillo3: #ffe48e;
$amarillo4: #fff6d8;
$azulPrincipal: #11214f;
$azulPrincipalClaro: #eaefff;
$azulSecundario: #1772c2;
$rojo: #e12c21;
$rojoClaro: #ffe6e4;
$naranja: #ff4d00;
$azulPrincipalClaro2: #dbe4ff;
$dmSans: "DM Sans";
// $righteous: "Righteous";
$righteous: "DM Sans";

html,
body,
#root {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $azulSecundario;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
}
body {
  font-family: $dmSans, sans-serif !important;
  margin: 0;
  box-sizing: border-box;
}
input,
select,
textarea {
  font-family: $dmSans, sans-serif !important;
}
h4 {
  margin: 0;
}
p {
  margin: 0;
}
ul li {
  list-style: none;
}

.ht__containerPrincipal {
  display: flex;
  height: 100%;
  position: relative;
}
.ht__containerContent {
  width: 90%;
  background-color: #f0f4f6;
  // height: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    // display: none;
    width: 2px;
    // background-color: $azulPrincipal !important;
  }
  // position: relative;
}
.mh__appRouter-load {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .ht__containerContent {
    width: 88%;
  }
}
@media screen and (max-width: 1024px) {
  .ht__containerPrincipal {
    flex-direction: column;
    height: auto;
  }
  .ht__containerContent {
    width: auto;
    overflow-y: scroll;
  }
  html,
  body,
  #root {
    height: auto;
    // background-color: rebeccapurple;
    // overflow: hidden;
  }
}
@media screen and (max-width: 474px) {
  .ht__containerContent {
    width: 100%;
    height: auto;
    overflow-y: scroll;
  }
}

@import "./variables";
@import "./mixins";
@import "./notification";
@import "./sidebar/sidebar";
@import "./headerScreen";
@import "./intereses/intereses";
@import "./intereses/interesesSelected";
@import "./intereses/inputFindInteres";
@import "./intereses/containerListIntereses";
@import "./intereses/interesesVacio";
@import "./libreria/libreriaScreen";
@import "./libreria/singleCardInteres";
@import "./libreria/loadingComponent";
@import "./filtros/filtro";
@import "./singleAudiencia/singleAudiencia";
@import "./singleAudiencia/containerInfoDescription";
@import "./singleAudiencia/containerGraphics";
@import "./graficas/grafica";
@import "./miCuenta/miCuenta";
@import "./miCuenta/equipoTrabajo";
@import "./reportes/reportesScreen";
@import "./reportes/reporteSpecifications";
@import "./reportes/engagementComponent";
@import "./reportes/paisesComponent";
@import "./reportes/cardsReport";
@import "./reportes/generoStatistics";
@import "./reportes/ageStatistics";
@import "./reportes/metrics";
@import "./reportes/modalDownload";
@import "./modalComponent/modal";
@import "./modalComponent/invitarMiembroModal";
@import "./modalComponent/planMedidaModal";
@import "./modalComponent/eliminarModal";
@import "./modalComponent/generarModal";
@import "./modalComponent/deleteInterest";
@import "./modalComponent/modalNoFBAccount";
@import "./modalComponent/modalFBAccountSynced";
@import "./login/loginScreen";
@import "./spinner/spinner";
@import "./planes/planes";
