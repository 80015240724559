.ht__loginScreen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ht__loginScreen-container {
  max-width: 992px;
  width: 100%;
  min-height: 477px;
  display: flex;
  box-shadow: 0px 4px 20px rgba(79, 82, 88, 0.2);
  border-radius: 8px;
  & > div:nth-child(1) {
    padding: 56px;
    width: 45%;
    background-color: $azulPrincipal;
    background-image: url("../../assets//img/background_login.png");
    background-position: center;
    background-size: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    .ht__loginScreen-container-logo {
      margin-bottom: 24px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: #fff;
    }
  }
  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;
    // background-color: red;
    // padding: ;
  }
}
.ht__loginScreen-container-info {
  width: 80%;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
    color: $azulPrincipal;
    font-family: $righteous;
    margin-bottom: 29px;
  }
  & > div:nth-child(2) {
    // background-color: rebeccapurple;
    // display: flex;
    // flex-direction: column;
    & > div {
      position: relative;
      img {
        position: absolute;
        top: 23px;
        left: 20px;
      }
    }
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: $negro1;
    }
    input[type="email"] {
      margin-bottom: 24px;
    }
    input[type="password"] {
      margin-bottom: 12px;
    }
    input {
      width: 85.3%;
      margin-top: 13px;
      padding: 13.5px 18px 13.5px 50px;
      border: 1px solid #d9dcde;
      border-radius: 4px;
      //   margin-bottom: 12px;
    }
  }
  & > p {
    text-align: end;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $azulSecundario;
    margin-bottom: 32px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__loginScreen-btnLogin {
  background-color: $azulSecundario;
  padding: 10px 0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: $blanco;
  text-transform: uppercase;
  text-align: center;
  &:hover {
    cursor: pointer;
  }
}
.ht__recoverPass-volver {
  margin-top: 50px;
  &:hover {
    cursor: pointer;
  }
}
.ht__login-error {
  background-color: rgb(245, 56, 56);
  border-radius: 4px;
  padding: 5px 1rem;
  margin-bottom: 10px;
  // position: absolute;
  // top: 2%;
  // right: .5%;
  p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 0.85rem;
  }
}
