.ht__paisesComponent {
  padding-bottom: 19px;
}
.ht__paisesComponent-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $gris6;
  padding: 10px 0;
  & > div {
    text-align: start;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $negro1;
    img {
      margin-right: 8px;
    }
  }
  & > div:nth-child(1) {
    width: 34%;
    padding-left: 84px;
  }
  & > div:nth-child(2),
  & > div:nth-child(3),
  & > div:nth-child(4),
  & > div:nth-child(5),
  & > div:nth-child(6) {
    width: 12%;
  }
  & > div:nth-child(7) {
    width: 6%;
  }
}

.ht__paisesComponent-table-content {
  .ht__rowCountryTable {
    border-bottom: 1px solid $gris4;
    &:last-child {
      border-bottom: none;
    }
    & > div:nth-child(1) {
      text-align: start;
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      padding: 10px 0;
      & > div:nth-child(1) {
        width: 34%;
        padding-left: 84px;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $negro1;
        svg {
          margin-right: 13px;
          height: 32px;
          width: 32px;
        }
      }
      & > div:nth-child(2),
      & > div:nth-child(3),
      & > div:nth-child(4),
      & > div:nth-child(5),
      & > div:nth-child(6) {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $negro1;
        width: 12%;
        span {
          font-size: 14px;
          line-height: 18px;
        }
      }
      & > div:nth-child(7) {
        width: 6%;
        img {
          display: none;
        }
      }
    }
    & > div:nth-child(2) {
      display: flex;
      flex-wrap: wrap;
      padding: 0 14px;
      & > div {
        width: 33.33%;
        margin-bottom: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        // p {
        span {
          font-size: 12px;
          line-height: 16px;
        }
        // }

        .header {
          display: flex;
          align-items: center;
          margin-bottom: 2px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          justify-content: flex-start;
          img {
            margin-right: 6px;
          }
        }
      }
    }
  }
}
.ht__paisesComponent-table-content-mobile {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.ht__paisesComponent-table-content-mobile-show {
  max-height: 1200px;
}

@media screen and (max-width: 1320px) {
  .ht__paisesComponent-table-header {
    & > div:nth-child(1) {
      width: 25%;
    }
    & > div:nth-child(2),
    & > div:nth-child(3),
    & > div:nth-child(4),
    & > div:nth-child(5),
    & > div:nth-child(6) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $negro1;
      width: 13.8%;
      span {
        font-size: 14px;
        line-height: 18px;
      }
    }
    & > div:nth-child(7) {
      width: 6%;
    }
  }
  .ht__paisesComponent-table-content {
    .ht__rowCountryTable {
      & > div:nth-child(1) {
        & > div:nth-child(1) {
          width: 25%;
        }
        & > div:nth-child(2),
        & > div:nth-child(3),
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6) {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 21px;
          color: $negro1;
          width: 13.8%;
          span {
            font-size: 14px;
            line-height: 18px;
          }
        }
        & > div:nth-child(7) {
          width: 6%;
        }
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .ht__paisesComponent-table-header {
    & > div:nth-child(1) {
      padding-left: 40px;
    }
  }
  .ht__paisesComponent-table-content {
    .ht__rowCountryTable {
      & > div:nth-child(1) {
        & > div:nth-child(1) {
          padding-left: 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 774px) {
  .ht__paisesComponent-table-header {
    & > div:nth-child(1) {
      width: 60%;
    }
    & > div:nth-child(2) {
      width: 33%;
    }
    & > div:nth-child(3),
    & > div:nth-child(4),
    & > div:nth-child(5),
    & > div:nth-child(6) {
      display: none;
    }
  }
  .ht__paisesComponent-table-content {
    .ht__rowCountryTable {
      & > div:nth-child(1) {
        & > div:nth-child(1) {
          width: 60%;
        }
        & > div:nth-child(2) {
          width: 33%;
        }
        & > div:nth-child(3),
        & > div:nth-child(4),
        & > div:nth-child(5),
        & > div:nth-child(6) {
          display: none;
        }
        & > div:nth-child(7) {
          img {
            display: block;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 474px) {
  .ht__paisesComponent-table-header {
    & > div:nth-child(1) {
      padding-left: 14px;
    }
  }
  .ht__paisesComponent-table-content {
    .ht__rowCountryTable {
      & > div:nth-child(1) {
        & > div:nth-child(1) {
          padding-left: 14px;
        }
      }
    }
  }
}
