.ht__modalDeleteInterest {
  margin: 32px;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 18px;

    & > img:nth-child(1) {
      width: 14%;
    }
    & > img:nth-child(2) {
      position: absolute;
      top: 0;
      right: 0;
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    text-align: center;
    color: $negro1;
    font-family: $righteous;
    margin-bottom: 12px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $gris1;
    margin-bottom: 39px;
  }
  .container-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > div:nth-child(1) {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: $azulPrincipal;
      &:hover {
        cursor: pointer;
      }
    }
    & > div:nth-child(2) {
      background-color: $rojo;
      border-radius: 2px;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      font-family: $righteous;
      padding: 10px 20px;
      margin-left: 22px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
