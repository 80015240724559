.ht__sidebar {
  width: 15%;
  background-color: $blanco;
  padding: 22px 20px 0px 22px;
  box-shadow: 2px 0px 4px rgba(230, 236, 239, 0.6);
  height: 97.5%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  & > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;
    & > div:nth-child(2) {
      margin-bottom: 17px;
      position: relative;
      & > p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: $negro2;
        margin-bottom: 8px;
        padding: 12px;
        &:hover {
          cursor: pointer;
          background-color: #f4f7ff;
        }
      }

      & > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 32px;
        // background-color: red;
        // position: relative;
        &:hover {
          cursor: pointer;
        }
        & > div {
          display: flex;
          align-items: center;
        }
        // justify-content: space-between;
        .avatar {
          // width: 10%;
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          // line-height: 48px;
          color: $blanco;
          background-color: $azulPrincipal;
          border-radius: 50%;
          // padding: 0 10px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 42px;
          width: 46px;
        }
        .infoUser {
          // background-color: rebeccapurple;
          p:nth-child(1) {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            color: $azulPrincipal;
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $negro2;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .container-icon-close {
    display: none;
  }
}
.ht__sidebar-container-menuShow {
  display: flex !important;
  flex-direction: column;
  background-color: $blanco;
  position: absolute;
  left: 112%;
  bottom: 0;
  border: 1px solid $gris4;
  border-radius: 8px;
  z-index: 2;
  .userActive {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px;
    .avatarA {
      border-radius: 50%;
      background-color: #ff9c01;
      color: $negro1;
      height: 30px;
      width: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      margin-right: 10.5px;
    }
    p {
      color: $negro2;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .workspace {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 87.5%;
    padding: 16px 19px;
    border-top: 1px solid $gris5;
    border-bottom: 1px solid $gris5;
    & > div {
      &:hover {
        cursor: pointer;
      }
      p:nth-child(1) {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $gris1;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $negro1;
      }
    }
  }
  .options {
    width: 85%;
    padding: 16px 19px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      width: 100%;
      text-align: start;
      // margin-bottom: 22px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $negro1;
      padding: 10px 10px 10px 17px;
      &:hover {
        cursor: pointer;
        background-color: $azulPrincipalClaro;
      }
    }
    p:last-of-type {
      margin-bottom: 0;
    }
  }
  .options-workspace-hide {
    display: none !important;
  }
  .options-workspace {
    position: absolute;
    background-color: $blanco;
    z-index: 1;
    display: flex;
    flex-direction: column;
    top: 23%;
    right: -58%;
    border: 1px solid #e6ecef;
    border-radius: 8px;

    .user-active {
      background-color: $azulPrincipalClaro;
      width: 72%;
    }
    p:nth-child(1) {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $gris1;
      padding: 12px 18px;
    }
    p {
      padding: 11px 18px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro1;
    }
  }
}
.ht__sidebar-container-menuWorkspace {
  // background-color: $blanco;
  // position: absolute;
  // left: 270px;
  // bottom: -35px;
  display: none !important;
  // display: flex;
  // flex-direction: column;
  // border: 1px solid $gris4;
  // border-radius: 8px;
  // z-index: 1;
  // .userActive {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 18px;
  //   .avatarA {
  //     border-radius: 50%;
  //     background-color: #ff9c01;
  //     color: $negro1;
  //     height: 30px;
  //     width: 30px;
  //     display: flex;
  //     justify-content: center;
  //     align-items: center;
  //     font-weight: bold;
  //     margin-right: 10.5px;
  //   }
  //   p {
  //     color: $negro2;
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 12px;
  //     line-height: 18px;
  //   }
  // }
  // .workspace {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   width: 87.5%;
  //   padding: 16px 19px;
  //   border-top: 1px solid $gris5;
  //   border-bottom: 1px solid $gris5;
  //   & > div {
  //     p:nth-child(1) {
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 12px;
  //       line-height: 18px;
  //       color: $gris1;
  //     }
  //     p {
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 14px;
  //       line-height: 21px;
  //       color: $negro1;
  //     }
  //   }
  // }
  // .options {
  //   width: 87.5%;
  //   padding: 16px 19px;
  //   p {
  //     margin-bottom: 22px;
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 14px;
  //     line-height: 21px;
  //     color: $negro1;
  //   }
  //   p:last-of-type {
  //     margin-bottom: 0;
  //   }
  // }
}
.ht__sidebar-logo {
  width: 100%;
  img {
    width: 40%;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__sidebar-containerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ht__sidebar-mobileMenu {
  display: none;
  img {
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__sidebar-items {
  margin-top: 18px;
  & > div {
    margin-bottom: 8px;
    padding: 12px;
    border-radius: 2px;
    &:hover {
      cursor: pointer;
      background-color: #f4f7ff;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.ht__sidebar-itemActive {
  background-color: $azulPrincipalClaro;
  p {
    font-weight: 500 !important;
    color: $azulPrincipal;
  }
}
.ht__sidebar-showMenu {
  display: flex !important;
}

// @media screen and(min-width: 1700px) {
//   .ht__sidebar {
//     width: 15% !important;
//   }
// }

// @media screen and(min-width: 1400px) {
//   .ht__sidebar {
//     width: 18%;
//   }
// }

@media screen and (max-width: 1280px) {
  .ht__sidebar {
    width: 12%;
    height: 96.4%;
    .infoUser {
      width: 75%;
    }
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        & > div {
          .avatar {
            font-size: 18px;
            height: 32px;
            width: 32px;
          }
        }
      }
    }
  }
  .ht__sidebar-logo {
    width: 100%;
    img {
      width: 45%;
    }
  }
  .ht__sidebar-container-menuShow {
    width: 160px !important;
    left: 116%;
  }
}

@media screen and (max-width: 1124px) {
  .ht__sidebar {
    // background-color: red;
    & > div:nth-child(2) {
      & > div:nth-child(2) {
        // background-color: red;
        & > div {
          .avatar {
            // height: 37px;
            // width: 38px;
            height: 40px;
            width: 40px;
            font-size: 20px;
            // line-height: 2px;
            // border-radius: 100%;
            // padding: 0 10px;
            // display: flex;
            // align-items: center;
            margin-right: 8px;
          }
          .infoUser {
            // background-color: rebeccapurple;
            p:nth-child(2) {
              font-size: 10px !important;
              line-height: 16px !important;
            }
            p {
              font-size: 12px !important;
              line-height: 20px !important;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1024px) {
//   .ht__sidebar {
//     width: auto;
//     box-shadow: 0px 4px 18px rgba(34, 44, 81, 0.12);
//     margin-bottom: 20px;
//     padding-bottom: 15px;
//     padding-left: 16px;
//     padding-right: 16px;
//     & > div:nth-child(2) {
//       display: none;
//       padding: 10px;
//       height: 100vh;
//       width: auto;
//       position: fixed;
//       z-index: 1;
//       background-color: $blanco;
//       right: 0;
//       margin: 0;
//       top: 0;
//       box-shadow: -4px 0px 15px 2px rgba(170, 170, 170, 0.411);
//     }
//     .container-icon-close {
//       display: block;
//       img {
//         width: 22px;
//       }
//     }
//   }
// }
@media screen and (max-width: 1024px) {
  .ht__sidebar {
    width: auto;
    box-shadow: 0px 4px 18px rgba(34, 44, 81, 0.12);
    margin-bottom: 20px;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    & > div:nth-child(2) {
      display: none;
      padding: 10px;
      height: 100vh;
      width: auto;
      position: fixed;
      z-index: 1;
      background-color: $blanco;
      right: 0;
      margin: 0;
      top: 0;
      box-shadow: -4px 0px 15px 2px rgba(170, 170, 170, 0.411);
    }
    .container-icon-close {
      display: block;
      img {
        width: 22px;
      }
    }
  }
  // .ht__hide-submenu {
  //   display: none;
  // }
  .ht__sidebar-logo {
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .ht__sidebar-mobileMenu {
    display: block;
    img {
      width: 35px;
    }
  }
}

@media screen and (max-width: 520px) {
  .ht__sidebar-logo {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 474px) {
  .ht__sidebar-logo {
    width: 18%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
  .ht__sidebar {
    width: auto;
  }
}
@media screen and (max-width: 360px) {
  .ht__sidebar-logo {
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
  }
}
