.ht__invitarMiembroModal {
  margin: 48px;
  .title {
    display: flex;
    justify-content: center;
    position: relative;
    margin-bottom: 12px;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      font-family: $righteous;
      color: #000;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
    margin-bottom: 18px;
  }
}
.ht__invitarMiembroModal-input {
  position: relative;
  margin-bottom: 12px;
  input {
    border: 1px solid #d9dcde;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 14.5px 18px 14.5px 50px;
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
  }
  img {
    position: absolute;
    top: 14px;
    left: 20px;
  }
}
.ht__invitarMiembroModal-msg {
  background-color: $amarillo4;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 14px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    span {
      font-weight: 500;
    }
  }
}
.ht__invitarMiembroModal-btnEnviar {
  border-radius: 2px;
  background-color: $azulSecundario;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: $blanco;
  font-family: $righteous;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 474px) {
  .ht__invitarMiembroModal {
    margin: 32px 16px;
    .title {
      justify-content: flex-start;
      h4 {
        font-size: 20px;
        line-height: 29px;
      }
    }
    & > p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 18px;
    }
  }
  .ht__invitarMiembroModal-input {
    img {
      width: 20px;
    }
    input {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .ht__invitarMiembroModal-msg {
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .ht__invitarMiembroModal-btnEnviar {
    margin-top: 70px;
  }
}
