.ht__modalDownload {
  padding: 48px;
}
.ht__modalDownload-header {
  position: relative;
  text-align: center;
  margin-bottom: 24px;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: #000;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__modalDownload-inputs {
  // background-color: rebeccapurple;
  .ht__modalDownload-label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
    margin-bottom: 12px;
  }
  input,
  textarea,
  select {
    margin-bottom: 32px;

    border: 1px solid $gris3;
    font-size: 16px;
    line-height: 19px;
  }
  input::placeholder,
  textarea::placeholder {
    color: $gris1;
  }
  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
  }

  textarea {
    padding: 12px 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: 100px;
    resize: none;
    width: 92%;
  }
  input {
    padding: 10px 12px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    width: 94%;
  }
  select {
    padding: 10px 12px;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
}
.container-dropzone {
  border: 1px solid $gris3;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
  }
  p {
    font-size: 16px;
    line-height: 19px;
  }
}
.ht__modalDownload-labeltamanio {
  color: $gris1;
  font-size: 14px;
  margin-bottom: 32px;
}
.ht__modalDownload-btndownload {
  color: #fff;
  background-color: $azulSecundario;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
