.ht_containerListIntereses {
  // margin-top: 24px;
  background-color: $blanco;
  border-radius: 8px;
  border: 1px solid $gris4;
  .container-parrafo {
    padding-left: 11px;
    margin-top: 8px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $gris1;
    }
  }
  .container-suggestions {
    padding-left: 11px;
    margin-top: 18px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .interes {
      background-color: $amarillo4;
      padding: 8px 14px;
      margin-right: 11px;
      margin-bottom: 10px;
      &:hover {
        cursor: pointer;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 21px;
        color: $negro1;
      }
    }
    .active {
      background-color: $amarillo;
      p {
        color: $negro1;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}
.ht_containerListIntereses-header {
  padding: 19px 19px;
  // padding: 0 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafcfc;
}
.ht_containerListIntereses-header-left {
  display: flex;
  align-items: center;
  width: 25%;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: $negro1;
  }

  // background-color: red;
}
.ht_containerListIntereses-header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 75%;
  .container-filtro {
    display: flex;
    align-items: center;
    border: 1px solid $gris3;
    border-radius: 4px;
    padding: 8px 14px;
    margin-right: 15px;
    &:hover {
      cursor: pointer;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $azulPrincipal;
      margin-left: 14px;
    }
  }
  .container-reset {
    display: flex;
    align-items: center;
    border: 1px solid $gris3;
    border-radius: 4px;
    padding: 11px 13.5px;
    &:hover {
      cursor: pointer;
    }
  }
}
.container-filtro-filtersNum {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: $amarillo3;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #a35800;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ht_containerListIntereses-list-containerinput {
  position: relative;
  margin-right: 20px;
  width: 30%;

  input {
    width: 83%;
    padding: 12px 12px 12px 36px;
    &:focus {
      outline: none;
    }
  }
  img {
    position: absolute;
    top: 13px;
    left: 12px;
  }
}

.ht_containerListIntereses-list-input {
  width: 100%;
  padding: 8px 10px 8px 40px;
  border: 1px solid $gris4;
  border-radius: 4px;
  // padding: ;
}

.ht_containerListIntereses-list {
  min-height: 300px;
  margin-top: 18px;
}
.ht_containerListIntereses-noList {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  // background-color: aliceblue;
  padding-top: 98px;
  padding-bottom: 100px;
  width: 80%;
  margin: 0 auto;
  & > div {
    // position: absolute;
    // top: 17%;
    // left: 12%;
    display: flex;
    align-items: flex-end;
    img {
      margin-right: 15px;
    }
    h4 {
      font-family: $righteous;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: $azulPrincipal;
      margin-bottom: 4px;
      padding-bottom: 5px;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $gris1;
  }
}
.ht__containerListIntereses-row {
  display: flex;
  margin-top: 39px;
  // align-items: center;
}
.ht_containerListIntereses-suggestions {
  width: 50%;
  border-left: 1px solid $gris4;
  padding-left: 11px;
  padding-right: 11px;
  & > p {
    padding-left: 11px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 13px;
    .num-intereses {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $gris1;
      margin-left: 8px;
    }
    span {
      font-weight: 500;
    }
  }
}
.data-table {
  ::-webkit-scrollbar {
    display: none;
  }
  & > div {
    & > div {
      .rdt_TableBody {
        & > div {
          // background-color: red;
          min-height: max-content;
          padding: 8px 0;
        }
      }
    }
  }
}
.rdt_TableRow {
  min-height: none !important;
  & > div {
    padding-left: 9px;
    & > div {
      height: max-content !important;
      // padding: 5px;
    }
  }
}
.ht_containerListIntereses-interests {
  padding: 0px 10px;
  width: 50%;
  & > p {
    padding-left: 9px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 13px;
    .num-intereses {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: $gris1;
      margin-left: 8px;
    }
    span {
      font-weight: 500;
    }
  }
}

.ht_containerListIntereses-loadSuggestion {
  // max-height: max-content;
  margin-top: 20px;
  p {
    font-size: 16px;
    padding-bottom: 60px;
    color: $azulPrincipal;
    text-align: center;
  }
}

.rdt_Table {
  & > div {
    &:hover {
      cursor: pointer;
    }
  }
}
.rdt_TableHeadRow {
  background-color: $gris6 !important;
  border-bottom: none !important;
  min-height: 41px !important;
  & > div:nth-child(1) {
    & > div {
      padding-left: 9px;
    }
  }
  & > div {
    padding-left: 0 !important;
    & > div {
      & > div:nth-child(1) {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $gris1;
      }
    }
  }
}

.rdt_TableBody {
  & > div {
    & > div:nth-child(1) {
      & > div {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $azulPrincipal;
        padding-left: 0;
      }
    }
    & > div {
      & > div {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $gris1;
      }
    }
  }
}
.rdt_TableHeader {
  display: none !important;
}

.ht__icon-reset {
  // &:hover {
  // -webkit-transform: rotateY(180deg);
  // -webkit-transform-style: preserve-3d;
  // transform: rotateY(180deg);
  // transform-style: preserve-3d;
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  // }
}
.ht_logsAi {
  padding-left: 9px;
  margin-bottom: 15px;
}
.ht_logsAi-containerList {
  margin-top: 10px;
}

@media screen and (max-width: 1708px) {
  .ht_containerListIntereses-list-containerinput {
    input {
      width: 80%;
      padding: 12px 12px 12px 36px;
    }
  }
}
@media screen and (max-width: 1490px) {
  .ht_containerListIntereses-list-containerinput {
    input {
      width: 77%;
      padding: 12px 12px 12px 36px;
    }
  }
}
@media screen and (max-width: 1325px) {
  .ht_containerListIntereses-list-containerinput {
    width: 40%;
    input {
      width: 80%;
      padding: 12px 12px 12px 36px;
    }
  }
}
@media screen and (max-width: 1280px) {
  .rdt_TableBody {
    & > div {
      & > div:nth-child(1) {
        & > div {
          font-size: 14px;
          line-height: 17px;
        }
      }
      & > div {
        & > div {
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }
}
@media screen and (max-width: 1046px) {
  .ht_containerListIntereses-list-containerinput {
    input {
      width: 77%;
      padding: 12px 12px 12px 36px;
    }
  }
}

@media screen and (max-width: 922px) {
  .ht_containerListIntereses {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 784px) {
  .data-table {
    width: 100%;
  }
  .ht__containerListIntereses-row {
    flex-direction: column;
    & > div {
      width: 100%;
    }
  }
  .ht_containerListIntereses-interests {
    padding: 0;
  }
  .ht_containerListIntereses-suggestions {
    margin-top: 30px;
    padding: 0;
  }
  .ht_containerListIntereses-header {
    flex-direction: column;
    & > div {
      width: 100%;
    }

    .container-filtro {
      p {
        font-size: 12px;
        line-height: 17px;
      }
      img {
        width: 18px;
      }
    }
  }
  .ht_containerListIntereses-header-right {
    justify-content: flex-start;
    margin-top: 10px;
  }
  .ht_containerListIntereses-list-containerinput {
    width: 90%;
    margin-right: 12px;
  }
}

@media screen and (max-width: 474px) {
  .ht_containerListIntereses-noList {
    width: 90%;
    padding-top: 35px;
    & > div {
      img {
        display: none;
      }
    }
  }
  .ht_containerListIntereses-header {
    & > p {
      .num-intereses {
        font-size: 12px;
        line-height: 18px;
      }
      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .ht_containerListIntereses {
    .container-suggestions {
      .interes {
        p {
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
  }
}
@media screen and (max-width: 420px) {
  .ht_containerListIntereses-header-right {
    width: 100%;
  }
  .ht_containerListIntereses-list-containerinput {
    width: 70% !important;
    // background-color: rebeccapurple;
    input {
      width: 75%;
    }
    // margin-right: 12px;
  }
  .rdt_TableHeadRow {
    & > div:nth-child(1) {
      & > div {
        padding-left: 0;
      }
    }
  }

  .rdt_TableBody {
    & > div {
      & > div:nth-child(1) {
        & > div {
          padding-left: 0;
        }
      }
    }
    & > div {
      & > div {
        & > div {
          font-size: 12px !important;
          line-height: 15px;
        }
      }
    }
  }
}
@media screen and (max-width: 336px) {
  .ht_containerListIntereses-list-containerinput {
    width: 60% !important;
    // background-color: rebeccapurple;
    input {
      width: 65%;
    }
    // margin-right: 12px;
  }
  .rdt_TableHeadRow {
    & > div:nth-child(1) {
      & > div {
        padding-left: 0;
      }
    }
  }

  .rdt_TableBody {
    & > div {
      & > div:nth-child(1) {
        & > div {
          padding-left: 0;
        }
      }
    }
  }
}
