.ht__metricsPage {
  padding: 32px;
  .ht__generarReporte-header {
    border-bottom: none;
    padding: 0;
    padding-bottom: 32px;
  }
}

.ht__metricsPage-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $azulPrincipalClaro;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  padding: 10px 12px;
  margin-bottom: 12px;
  &:hover {
    cursor: pointer;
  }
}
.ht__metricsPage-single-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
