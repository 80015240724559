.ht__singleAudiencia {
  padding: 47px 60px;
  // .ht_containerListIntereses {
  //   padding-top: 32px;
  // }
}
.ht__singleAudiencia-containerInfo {
  background-color: $blanco;
  // display: flex;
  margin-bottom: 30px;
  padding: 32px;
}

// @media screen and (max-width: 1500px) {
//   .ht__singleAudiencia-containerInfo {

//   }
// }
// @media screen and (max-width: 1350px) {
//   .ht__singleAudiencia-containerInfo {
//     & > div:nth-child(2) {
//       // width: 70%;
//       padding-left: 10px;
//     }
//   }
// }
// @media screen and (max-width: 1150px) {
//   .ht__singleAudiencia-containerInfo-img {
//     // width: 100%;
//     height: 300px;
//     img {
//       height: 300px;
//     }
//   }
//   .ht__singleAudiencia-containerInfo {
//     & > div:nth-child(1) {
//       width: 35%;
//     }
//     & > div:nth-child(2) {
//       width: 65%;
//     }
//   }
// }
// @media screen and (max-width: 1048px) {
//   .ht__singleAudiencia-containerInfo-img {
//     // width: 100%;
//     height: 260px;
//     img {
//       height: 260px;
//     }
//   }
// }
// @media screen and (max-width: 1024px) {
//   .ht__singleAudiencia-containerInfo {
//     & > div:nth-child(1) {
//       width: 40%;
//     }
//     & > div:nth-child(2) {
//       width: 60%;
//     }
//   }
// }
// @media screen and (max-width: 990px) {
//   .ht__singleAudiencia-containerInfo {
//     flex-direction: column;
//     & > div:nth-child(1) {
//       width: auto;
//     }
//     & > div:nth-child(2) {
//       // background-color: rebeccapurple;
//       width: auto;
//       align-items: center;
//     }
//   }
//   .ht__singleAudiencia-containerInfo-img {
//     // width: 100%;
//     height: 400px;
//     img {
//       height: 400px;
//     }
//   }
// }

@media screen and (max-width: 550px) {
  .ht__singleAudiencia {
    padding: 22px 16px;
    .ht_containerListIntereses {
      margin-left: 0;
      margin-right: 0;
    }
  }
  // .ht__singleAudiencia-containerInfo-img {
  //   // width: 100%;
  //   height: 300px;
  //   img {
  //     height: 300px;
  //   }
  // }
  // .ht__singleAudiencia-containerInfo {
  //   & > div:nth-child(1) {
  //     padding: 24px;
  //   }
  // }
  .ht__graphSize-leyenda {
    & > div {
      p {
        font-size: 14px !important;
        line-height: 16.41px !important;
      }
    }
  }
}
// @media screen and (max-width: 360px) {
//   .ht__singleAudiencia-containerInfo-img {
//     // width: 100%;
//     height: 250px;
//     img {
//       height: 250px;
//     }
//   }
// }
