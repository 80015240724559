.ht__planes {
  // height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // padding-top: 8%;
  // background-color: red;
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 29px;
    color: $azulPrincipal;
    margin-bottom: 40px;
  }
}
.plan-margin-rigth {
  margin-right: 30px;
  .body {
    & > div:nth-child(1) {
      margin-bottom: 0 !important;
    }
  }
}
.ht__planes-container {
  display: flex;
  // align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 50px;
}
.ht__planes-container-lite {
  background-color: $blanco;
  width: 31%;
  border-radius: 8px;
  border: 1px solid $gris4;
  .header {
    text-transform: uppercase;
    text-align: center;
    background-color: $azulPrincipalClaro2;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $azulPrincipal;
    padding: 20px 0;
  }
  .body {
    padding: 40px 38px 32px 38px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 18px;
      color: $negro1;
    }
  }
}

.ht__planes-container-pro {
  background-color: $blanco;
  width: 45%;
  // width: 31%;
  border-radius: 8px;
  border: 1px solid $gris4;
  position: relative;
  overflow: hidden;
  // max-height: 500px;
  .ht__planes-container-ribbon {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    .ribbon {
      text-align: center;
      right: 0;
      width: 200%;
      -webkit-transform: translateY(-60%) translateX(-23%) translateX(35px)
        rotate(45deg);
      -ms-transform: translateY(-50%) translateX(-23%) translateX(35px)
        rotate(45deg);
      transform: translateY(-50%) translateX(-41%) translateX(38px)
        rotate(45deg);
      margin-top: 3.2em;
      font-size: 14px;
      line-height: 18px;
      font-weight: 500;
      text-transform: none;
      background: $amarillo2;
      color: $azulPrincipal;
      padding: 8px 0;
    }
  }
  .header {
    padding: 20px 0;
    background-color: $azulPrincipal;
    p {
      text-transform: uppercase;
      text-align: center;
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      color: #fff;
    }
  }
  .body {
    height: 72%;
    // background-color: red;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    padding: 40px 32px 32px 32px;
    & > div:nth-child(1) {
      margin-bottom: 5em;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $negro1;
        margin-bottom: 12px;
        span {
          font-weight: 700;
        }
      }
    }
  }
}
.text-ai {
  position: absolute;
  font-size: 12px;
  line-height: 14px;
  padding-left: 5px;
  top: 0;
}
.ht__planPro-note {
  background-color: $amarillo4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 12px;
  // margin-top: 78px;
  img {
    margin-right: 13px;
  }
  .ht_textuses-relative {
    position: relative;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $negro1;
  }
  .ht_textuses {
    margin-left: 62px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $negro1;
  }
}
.ht__planComingSoon {
  background-color: $blanco;
  width: 31%;
  border-radius: 8px;
  border: 1px solid $gris4;
  .header {
    text-transform: uppercase;
    text-align: center;
    background-color: $gris4;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $gris1;
    padding: 20px 0;
  }
  .body {
    height: 58%;
    // background-color: red;
    display: flex;
    padding: 40px 32px 32px 32px;
    flex-direction: column;
    justify-content: flex-end;
    .message {
      background-color: $gris5;
      border-radius: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $negro1;
      padding: 12px;
    }
  }
}
.ht__planPro-date {
  margin-top: 10px;
  padding-left: 10px;
  p {
    font-size: 13px;
  }
}
.ht__planPro-payBtn {
  margin-top: 25px;
  width: 95%;
  padding: 10px;
  text-align: center;
  border-radius: 4px;
  background-color: $azulPrincipal;
  color: #fff;
  font-size: 14px;
  &:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 1280px) {
  .ht__planComingSoon {
    .body {
      height: 60%;
    }
  }
}

@media screen and (max-width: 1068px) {
  .ht__planes-container-pro,
  .ht__planComingSoon,
  .ht__planes-container-lite {
    width: 32%;
  }
}
@media screen and (max-width: 1060px) {
  .ht__planes {
    padding: 14px 16px;
  }
  .ht__planes-container {
    flex-wrap: wrap;
  }
  .ht__planes-container-pro,
  .ht__planComingSoon,
  .ht__planes-container-lite {
    width: 49% !important;
    margin-bottom: 20px;
  }
  .ht__planComingSoon {
    .body {
      height: 40%;
    }
  }
}
@media screen and (max-width: 920px) {
  .ht__planComingSoon {
    .body {
      height: auto;
    }
  }
}
@media screen and (max-width: 500px) {
  .ht__planes {
    h4 {
      font-size: 20px;
      line-height: 29px;
      margin-bottom: 18px;
    }
  }
  .ht__planes-container {
    flex-direction: column;
    width: 100%;
  }
  .ht__planes-container-pro,
  .ht__planComingSoon,
  .ht__planes-container-lite {
    .header {
      font-size: 18px;
      line-height: 23px;
    }
    width: 100% !important;
    margin-bottom: 20px;
  }
}
