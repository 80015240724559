.ht__modalComponent {
  position: absolute;
  // background: #000000;
  // opacity: 0.4;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fb-modalclass {
  width: 83.7%;
  right: 0;
  left: auto;
  // left: auto;
  // height: auto;
  .ht__modalComponent-children {
    height: auto;
  }
}
.ht__modalComponent-children {
  // max-width: 586px;
  width: 586px;
  height: 590px;
  overflow-y: scroll;
  // min-height: 600px;
  background-color: $blanco;
  z-index: 2;
  // position: absolute;
  // top: 18%;
  // left: 35%;
  box-shadow: 0px 2px 22px rgba(34, 44, 81, 0.16);
  border-radius: 8px;
  border: 1px solid #e6ecef;
}
@media screen and (max-width: 1350px) {
  .fb-modalclass {
    width: 83% !important;
  }
}
@media screen and (max-width: 1280px) {
  .fb-modalclass {
    width: 85% !important;
  }
}
@media screen and (max-width: 1020px) {
  .fb-modalclass {
    width: 100% !important;
    margin-top: 11%;
  }
}
@media screen and (max-width: 774px) {
  .ht__modalComponent-children {
    max-width: none;
    width: 100%;
    border-radius: 0px;
    box-shadow: none;
    border: none;
  }
  .ht__modalComponent {
    background-color: $blanco;
  }
}
@media screen and (max-width: 474px) {
  .ht__modalComponent {
    align-items: flex-start;
    // height: auto;
  }
}
