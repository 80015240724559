.ht__modalNoFbAccount {
  margin: 32px;
}
.ht__modalNoFbAccount-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 18px;
  position: relative;
}
.ht__modalNoFbAccount-header-icon {
  img {
    width: 55px;
  }
}
.ht__modalNoFbAccount-body {
  text-align: center;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: $negro1;
    margin-bottom: 12px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $gris1;
    margin: auto;
    margin-bottom: 22px;
    width: 90%;
  }
}
.ht__modalNoFbAccount-body-btnSync {
  background-color: $azulSecundario;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-family: $righteous;
  &:hover {
    cursor: pointer;
  }
}
.ht__modal-header-close {
  position: absolute;
  top: 0;
  right: 0;
  img {
    width: 23px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__modalLimit-btn {
  margin-top: 41px;
  background-color: $azulSecundario;
  border-radius: 2px;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  &:hover {
    cursor: pointer;
  }
}
