.ht__ageStatistics {
  margin-top: 42px;
}
.ht__ageStatistics-row:first-child {
  margin-bottom: 18px;
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #11214f;
  }
}
.ht__ageStatistics-row {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  // background-color: aliceblue;
  & > div:nth-child(1) {
    width: 20%;
    // background-color: rebeccapurple;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $negro1;
  }
  // & > div:nth-child(2),
  // & > div:nth-child(3) {
  //   width: 23%;
  //   // background-color: azure;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 14px;
  //   line-height: 18px;
  //   color: $negro1;
  // }
  // & > div:nth-child(4),
  // & > div:nth-child(5) {
  //   width: 16%;
  //   // background-color: red;
  //   font-weight: normal;
  //   font-size: 16px;
  //   line-height: 21px;
  //   color: $azulPrincipal;
  // }
  // & > div:nth-child(4) {
  //   text-align: center;
  // }
}
.ageStatics-header {
  & > div {
    text-align: start !important;
  }
  // & > div:nth-child(1){
  //   width: 20%;
  // }
  .ht__generoStatistics-reach,
  .ht__generoStatistics-clicks {
    width: 26.2%;
    // background-color: azure;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $negro1;
  }
  .ht__generoStatistics-clicks-cpc,
  .ht__generoStatistics-ctr {
    width: 12.9%;
    // background-color: red;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $azulPrincipal;
  }
}
.ht__ageStatistics-rowdiv-orange {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $naranja;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-blue {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: #1772c2;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-red {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $rojo;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-redLight {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $rojoClaro;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-yellow {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $amarillo;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-blueLight {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $azulPrincipalClaro;
    margin-right: 10px;
  }
}
.ht__ageStatistics-rowdiv-yellowLight {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $amarillo3;
    margin-right: 10px;
  }
}

.ht__ageStatistics-usd {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $gris2;
}
.ht__ageStatistics-row-yellow {
  background-color: $amarillo2;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__ageStatistics-row-yellowLight {
  background-color: $amarillo3;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__ageStatistics-row-orange {
  background-color: $naranja;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
  color: #fff;
}
.ht__ageStatistics-row-red {
  background-color: $rojo;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
  color: #fff;
}
.ht__ageStatistics-row-redLight {
  background-color: $rojoClaro;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__ageStatistics-row-blueLight {
  background-color: $azulPrincipalClaro2;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__ageStatistics-row-blue {
  background-color: $azulSecundario;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
  color: #fff;
}

.ht__ageStatistics-color-yellow {
  color: $amarillo;
}

.ht__ageStatistics-color-blue {
  color: $azulSecundario;
}
.ht__ageStatistics-color-red {
  color: $rojo;
}
.ht__ageStatistics-color-orange {
  color: $naranja;
}
.ht__ageminwidth {
  min-width: fit-content;
}
