.ht__graphSize {
  display: flex;
  align-items: center;
  margin-left: -10%;
  //   width: 100%;
  //   background-color: red;
  canvas {
    height: 170px !important;
    width: 170px !important;
  }
}
.ht__graphSize-leyenda {
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  & > p {
    display: none;
    margin-bottom: 17px;
  }
  & > div {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $negro2;
    }
    .ht__graphSize-leyenda-color,
    .ht__graphCategory-leyenda-color {
      margin-right: 6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
  .colorSize_1 {
    background-color: #ff9c01;
  }
  .colorSize_2 {
    background-color: #e12c21;
  }
  .colorSize_3 {
    background-color: #e6ecef;
  }
  // Category
  .colorCat_1 {
    background-color: #1772c2;
  }
  .colorCat_2 {
    background-color: #11214f;
  }
  .colorCat_3 {
    background-color: #dbe4ff;
  }
}
.ht__graphSize-titleChart {
  // background-color: #dbe4ff;

  position: relative;
  & > div:nth-child(1) {
    position: absolute;
    // background-color: #e12c21;
    top: 29%;
    // left: 19%;
    p:nth-child(1) {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $azulPrincipal;
    }
    p {
      text-align: center;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $negro2;
    }
  }
  .ht__graphSize-titleChart-category {
    // top: 23%;
    right: 32%;
  }
}

@media screen and (max-width: 1250px) {
  .ht__graphSize-leyenda {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1025px) {
  .ht__graphSize-titleChart {
    & > div:nth-child(1) {
      top: 31%;
      left: 35%;
    }
  }
  .ht__graphSize {
    justify-content: flex-start;
    margin-left: -25px;
  }
}
@media screen and (max-width: 474px) {
  .ht__graphSize {
    margin-left: -90px;
    margin-bottom: 36px;
  }
  .ht__graphSize-titleChart {
    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      & > p:nth-child(2) {
        display: none;
      }
      top: 33%;
      left: 35%;
    }
    .ht__graphSize-titleChart-category {
      top: 33%;
      left: 50% !important;
    }
  }
  .ht__graphSize-leyenda {
    & > p {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $negro2;
      margin-bottom: 12px;
    }
    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      .ht__graphSize-leyenda-color {
        margin-right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #ff9c01;
      }
    }
    & > div:nth-child(3) {
      display: flex;
      align-items: center;
      .ht__graphSize-leyenda-color {
        margin-right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #e12c21;
      }
    }
    & > div:nth-child(4) {
      display: flex;
      align-items: center;
      .ht__graphSize-leyenda-color {
        margin-right: 6px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #dbe4ff;
      }
    }
  }
}
@media screen and (max-width: 390px) {
  .ht__graphSize-leyenda {
    margin-left: -20px;
  }
  .ht__graphSize {
    margin-left: -60px;
  }
}
