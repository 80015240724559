.ht__cardInteres {
  background-color: $blanco;
  width: 32.5%;
  // margin-right: 16px;
  border-radius: 4px;
  margin-bottom: 24px;
  flex-basis: 1;
  height: 360px;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}
.ht__cardInteres-tag {
  position: absolute;
  background-color: $azulSecundario;
  padding: 4px 10px;
  top: 18px;
  left: 18px;

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $blanco;
  }
}

.ht__cardInteres-singleImg {
  width: 100%;
  height: 250px;
  img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}
.ht__cardInteres-infoInteres {
  padding: 18px;
  .title-interes {
    p {
      font-family: $righteous;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      color: $negro1;
    }
  }
  .num-interes {
    margin-top: 4px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro1;
    }
  }
  .description-interes {
    margin-top: 12px;
    padding-bottom: 2px;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $gris1;
    }
  }
}

@media screen and (max-width: 800px) {
  .ht__cardInteres {
    width: 48%;
  }
}
@media screen and (max-width: 550px) {
  .ht__cardInteres {
    width: 100%;
  }
}
