.ht__generarReporte-header {
  border-bottom: 2px solid $azulPrincipalClaro;
  padding: 32px;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
  }
  .title {
    position: relative;
    margin-bottom: 2px;
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 35px;
      color: #000;
      font-family: $righteous;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__generarPage1 {
  padding: 24px 32px;
  //   background-color: red;
  //   overflow: hidden;
  & > h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: #000;
    font-family: $righteous;
  }
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
    margin-bottom: 6px;
  }
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: #000;
    font-family: $righteous;
  }
  input,
  select {
    width: 100%;
    margin-top: 8px;
    border: 1px solid #d9dcde;
    border-radius: 2px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
    background-color: #fff;
    &:focus {
      outline: none;
    }
  }
  input::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
  }
  input {
    margin-bottom: 32px;
    width: 95%;
  }
  select {
    margin-bottom: 32px;
  }
}
.ht__generarPage1-grafico {
  margin-bottom: 32px;
}
.ht__generarPage1-containerBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btn {
    background-color: $azulSecundario;
    border-radius: 2px;
    width: max-content;
    padding: 10px 20px;
    color: $blanco;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    font-family: $righteous;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__generarPage2-containerBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: $azulPrincipal;
    &:hover {
      cursor: pointer;
    }
  }
  .btn {
    background-color: $azulSecundario;
    border-radius: 2px;
    width: max-content;
    padding: 10px 20px;
    color: $blanco;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    font-family: $righteous;
  }
}
.ht__generarPage1-fechas {
  .fecha {
    display: flex;
    align-items: center;
    h4 {
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;
      color: #000;
      font-family: $righteous;
      margin-right: 5px;
    }
  }
}
.ht__generarPage1-fechas-inputs {
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div {
    width: 45% !important;
    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro2;
    }

    input {
      width: 88%;
    }
  }
}
.ht__generarPage1-label {
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 16px !important;
  color: $negro2 !important;
}
.ht__generarPage3-metricas {
  margin-bottom: 90px;
  margin-top: -15px;
  & > p {
    padding-left: 3px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $azulSecundario;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__generarPage1-cuenta-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: $negro2;
}
.ht__generarPage1-cuenta {
  position: relative;
  border: 1px solid #d9dcde;
  border-radius: 2px;
  padding: 10px 12px;
  margin-bottom: 32px;
  margin-top: 8px;
  &:hover {
    cursor: pointer;
  }
}
// .ht__generarPage1-cuenta-value {
//   margin-bottom: 15px;
// }
.ht__generarPage1-cuenta-show-options {
  display: block !important;
}
.ht__generarPage1-cuenta-options {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #d9dcde;
  border-radius: 2px;
  max-height: 280px;
  overflow-y: scroll;
  top: 43px;
  left: 0;
  width: 100%;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
    padding: 10px 12px;
    // width: 100%;
    &:hover {
      background-color: $azulPrincipalClaro2;
    }
  }
}
