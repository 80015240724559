.ht__miCuenta {
  .ht_interesesScreen-tabbar {
    background-color: $gris5;
    padding: 32px 0;
  }
  .ht__interesesScreen-border {
    margin-bottom: 48px;
  }
}
.ht__miCuenta {
  padding: 0 60px 49px 60px;
  height: 63%;
}
.ht__miCuenta-container {
  background-color: $blanco;
  border: 1px solid #e6ecef;
  border-radius: 8px;
  padding: 32px;
  // display: flex;
  // justify-content: space-between;
  // & > div:nth-child(2) {
  //   // width: 45%;
  //   h4 {
  //     font-family: $righteous;
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 22px;
  //     line-height: 32px;
  //     color: $azulPrincipal;
  //   }
  //   .ht__miCuenta-container-formulario {
  //     margin-top: 32px;
  //     label {
  //       font-style: normal;
  //       font-weight: normal;
  //       font-size: 16px;
  //       line-height: 19px;
  //       color: $negro1;
  //       // margin-bottom: 12px;
  //     }
  //     .icon-input {
  //       position: relative;
  //       img {
  //         position: absolute;
  //         top: 40%;
  //         left: 20px;
  //       }

  //       input {
  //         width: 80%;
  //         border: 1px solid #d9dcde;
  //         border-radius: 4px;
  //         padding: 14.5px 18px;
  //         margin-top: 12px;
  //         font-style: normal;
  //         font-weight: normal;
  //         font-size: 16px;
  //         line-height: 19px;
  //         color: $gris1;
  //       }
  //       input:nth-child(1) {
  //         margin-bottom: 24px;
  //       }
  //       input[type="email"] {
  //         padding-left: 50px;
  //       }
  //       input[type="text"] {
  //         width: 85.5%;
  //       }
  //     }
  //   }
  // }
  // & > div:nth-child(2) {
  //   width: 40%;
  //   padding-top: 90px;
  //   & > p {
  //     font-style: normal;
  //     font-weight: normal;
  //     font-size: 18px;
  //     line-height: 26px;
  //     font-family: $righteous;
  //     color: $negro1;
  //     margin-bottom: 14px;
  //   }
  // }
}
.ht__miCuenta-container-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  // background-color: rebeccapurple;
  & > div:nth-child(1) {
    width: 50%;
    h4 {
      font-family: $righteous;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 32px;
      color: $azulPrincipal;
    }
    .ht__miCuenta-container-formulario {
      margin-top: 32px;
      label {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $negro1;
        // margin-bottom: 12px;
      }
      .icon-input {
        position: relative;
        img {
          position: absolute;
          top: 40%;
          left: 20px;
        }

        input {
          width: 89%;
          border: 1px solid #d9dcde;
          border-radius: 4px;
          padding: 14.5px 18px;
          margin-top: 12px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: $gris1;
        }
        input:nth-child(1) {
          margin-bottom: 24px;
        }
        // input[type="email"] {
        //   padding-left: 50px;
        // }
        // input[type="text"] {
        //   width: 85.5%;
        // }
      }
    }
  }
  & > div:nth-child(2) {
    width: 40%;
    padding-top: 30px;
    & > p {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      font-family: $righteous;
      color: $negro1;
      margin-bottom: 14px;
    }
  }
}
.ht__miCuenta-container-password {
  margin-top: 32px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
  }
  & > div {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro1;
    }
    .btn-change-pass {
      background-color: $gris5;
      border-radius: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro1;
      padding: 8px 0;
      width: 35%;
      text-align: center;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__miCuenta-container-idioma {
  margin-top: 32px;
  margin-bottom: 60px;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
    margin-bottom: 12px;
  }
  .btn-change-idioma {
    background-color: $gris5;
    width: 28%;
    padding: 9px 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    &:hover {
      cursor: pointer;
    }
    p {
      text-align: center;
    }
  }
}

// Dropdown
.ht__dropdown {
  background-color: $gris5;
  border-radius: 8px;
  width: 200px;
  position: relative;
  padding: 2px;
  height: auto;
  .ht__dropdown-btn {
    align-items: center;
    display: flex;
    margin: 5px;
    justify-content: space-between;
    cursor: pointer;
  }
  .ht__dropdown-content {
    background-color: $gris5;
    position: absolute;
    left: 0;
    top: 40px;
    width: 200px;
    height: auto;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .ht__dropdown-item {
    cursor: pointer;
    margin: 10px;
    text-align: start;
    &:hover {
      background-color: $gris3;
    }
  }
}

.ht__miCuenta-container-perfil {
  max-width: 100%;
  display: flex;
  align-items: center;
  background-color: $azulPrincipalClaro;
  border-radius: 4px;
  padding: 12px 0 12px 18px;
  & > div {
    display: flex;
    align-items: center;
    // background-color: rebeccapurple;
    // width: 30%;
    margin-right: 18px;
    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      //   width: 100%;
      object-fit: cover;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
  }
}
.ht__miCuenta-container-title {
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $azulPrincipal;
    font-family: $righteous;
  }
}

.ht__miCuenta-container-desvincularfb {
  margin-top: 14px;
  width: 100%;
  background-color: #3b5998;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 0;
  &:hover {
    cursor: pointer;
  }
  & > div {
    margin-right: 9.5px;
    display: flex;
    align-items: center;
  }
  p {
    font-family: $righteous;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    text-transform: capitalize;
    color: $blanco;
  }
}
.btn-facebook {
  background-color: #3b5998;
  color: $blanco;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  border-radius: 4px;
  padding: 8px 0;
  width: 100%;
  box-shadow: 0 2px 8px rgba(54, 54, 54, 0.219);
  border: none;
  &:hover {
    cursor: pointer !important;
  }
}

.ht__miCuenta-container-saveBtn {
  background-color: $azulSecundario;
  border-radius: 2px;
  text-align: center;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  padding: 10px 20px;
  margin-bottom: 32px;
  width: max-content;
  &:hover {
    cursor: pointer;
  }
}
.ht__miCuenta-inputPassword {
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  label {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
  }
}
.ht__miCuenta-inputPassword-row {
  margin-top: 12px;
  display: flex;
  width: 100%;
  input {
    width: 80%;
    border: 1px solid #d9dcde;
    border-radius: 4px;
    padding: 14.5px 18px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
    margin-right: 10px;
  }
  img {
    width: 5%;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__miCuenta-container-noaccountfb {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff6d8;
  border-radius: 8px;
  padding: 17px 16px;
  img {
    margin-right: 11px;
  }
  p {
    color: $gris1;
  }
}

@media screen and (max-width: 1250px) {
  .ht__miCuenta-container {
    .ht__miCuenta-container-row {
      & > div:nth-child(1) {
        width: 40%;
      }
      & > div:nth-child(2) {
        width: 50%;
      }
    }
  }
  .ht__miCuenta-container-idioma {
    .btn-change-idioma {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1150px) {
  .ht__miCuenta-container-perfil {
    & > div {
      img {
        width: 60px;
        height: 60px;
      }
    }
  }
}
@media screen and (max-width: 1120px) {
  .ht__miCuenta-container-password {
    & > div {
      flex-direction: column;
      align-items: flex-start;
      .btn-change-pass {
        margin-top: 12px;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 720px) {
  .ht__miCuenta-container {
    .ht__miCuenta-container-row {
      flex-direction: column-reverse;
      justify-content: flex-start;

      & > div:nth-child(1) {
        width: 100%;
      }
      & > div:nth-child(2) {
        padding-top: 24px;
        width: 100%;
        .ht__miCuenta-container-desvincularfb {
          width: 100%;
        }
      }
    }
  }
  .ht__miCuenta {
    padding: 0;
    padding-bottom: 60px;
    .ht__miCuenta-container {
      margin: 0 16px;
    }
  }
}
@media screen and (max-width: 474px) {
  .ht__miCuenta-container-perfil {
    width: auto !important;
  }
  .ht__miCuenta-container-formulario {
    .icon-input {
      input[type="email"] {
        width: 74% !important;
      }
    }
  }
  .ht__miCuenta-container {
    padding: 24px 26px;
  }
}
