.ht__eliminarModal {
  margin: 32px;
  text-align: center;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 18px;
    & > img {
      position: absolute;
      top: 0;
      right: 0;
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 35px;
    color: $negro1;
    font-family: $righteous;
    margin-bottom: 12px;
  }
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
    margin-bottom: 39px;
  }
}
.ht__eliminarModal-containerBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > div:nth-child(1) {
    margin-right: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: $azulPrincipal;
    font-family: $righteous;
  }
  & > div:nth-child(2) {
    background-color: $rojo;
    border-radius: 2px;
    color: #fff;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
  }
  & > div {
    padding: 10px 20px;
    &:hover {
      cursor: pointer;
    }
  }
}
