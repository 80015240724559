.ht__cardsReport {
  width: 100%;
}
.ht__cardsReport-header {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  font-family: $righteous;
  color: $azulPrincipal;
  & > div {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $azulPrincipalClaro;
    margin-right: 25px;
  }
}

.ht__cardsReport-container {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 86px;
  & > div {
    width: 18%;
    border: 1px solid $gris4;
    border-radius: 4px;
    padding: 24px 10px 24px 24px;
    margin-bottom: 10px;
    margin-right: 16px;
    overflow: hidden;
    & > p:nth-child(1) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $gris1;
      text-overflow: ellipsis;
    }
    & > p:nth-child(2) {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $azulPrincipal;
    }
  }
}

@media screen and (max-width: 1012px) {
  .ht__cardsReport-container {
    & > div {
      width: 24%;
    }
  }
}
@media screen and (max-width: 700px) {
  .ht__cardsReport-container {
    & > div {
      width: 40%;
    }
  }
}
@media screen and (max-width: 594px) {
  .ht__cardsReport-container {
    & > div {
      width: 100%;
    }
  }
}
