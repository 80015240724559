.ht__reportesScreen {
  padding: 44px 60px;
  // height: 80%;
  // background-color: rebeccapurple;
}
.ht__reportesScreen-container {
  background-color: $blanco;
  border: 1px solid $gris4;
  border-radius: 8px;
  padding-bottom: 7px;
}
.ht__reportesScreen-container-header {
  padding: 32px 32px 18px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    font-family: $righteous;
    color: $azulPrincipal;
  }
  & > div {
    display: flex;
    align-items: center;
    .icon-find {
      margin-right: 19px;
      &:hover {
        cursor: pointer;
      }
    }
    select {
      border: 1px solid $gris3;
      border-radius: 4px;
      padding: 12px 14px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: $negro2;
      margin-right: 18px;
    }
  }
}
.ht__reportesScreen-container-header-btnGenerar {
  background-color: $azulSecundario;
  border-radius: 2px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-family: $righteous;
  color: $blanco;
  padding: 10px 16px;
  //   width: 30%;
  &:hover {
    cursor: pointer;
  }
}
.ht__reportesScreen-container-table-content {
  border-bottom: 1px solid $gris4;
  margin-top: 19.5px;
  background-color: $blanco !important;
}
.ht__reportesScreen-container-table-content:hover {
  cursor: pointer;
}
.ht__reportesScreen-container-table-header,
.ht__reportesScreen-container-table-content {
  background-color: $gris6;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 0;
  & > div {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
  }
  & > div:nth-child(1) {
    width: 40%;
    padding-left: 64px;
    & > div:nth-child(1) {
      display: flex;
      align-items: center;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $azulPrincipal;
      }
      img {
        margin-right: 15px;
      }
    }
    .all-campanias-show {
      max-height: 1000px !important;
    }
    .all-campanias {
      // padding: 0 18px;
      padding-left: 33px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.4s ease;
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $gris1;
        margin: 0;
        padding-bottom: 16px;
        padding-top: 12px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $azulPrincipal;
        padding-bottom: 12px;
      }
    }
  }
  & > div:nth-child(2) {
    width: 24%;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $azulPrincipal;
      padding: 4px 8px;
      background-color: $azulPrincipalClaro;
      border-radius: 50px;
      width: max-content;
    }
  }
  & > div:nth-child(3) {
    width: 15%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
  }
  & > div:nth-child(4) {
    width: 15%;
    display: flex;
    & > div:nth-child(1) {
      margin-right: 16px;
    }
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  & > div:nth-child(5) {
    width: 6%;
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__reportesScreen-noReport {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  & > div {
    margin-bottom: 19px;
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $azulPrincipal;
    font-family: $righteous;
    margin-bottom: 9px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $gris1;
    margin-bottom: 32px;
  }
  .btn-reporte {
    background-color: $azulSecundario;
    color: $blanco;
    border-radius: 2px;
    padding: 10px 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 23px;
    font-family: $righteous;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__reportesScreen-container-table-nameMobile {
  display: none;
}
.ht__reportesScreen-container-table-actionsMobile {
  display: none;
}

@media screen and (max-width: 1050px) {
  .ht__reportesScreen-container-table-header,
  .ht__reportesScreen-container-table-content {
    & > div:nth-child(1) {
      width: 30%;
    }
    & > div:nth-child(2) {
      width: 245;
    }
    & > div:nth-child(3) {
      width: 15%;
    }
    & > div:nth-child(4) {
      width: 11%;
    }
    & > div:nth-child(5) {
      width: 6%;
    }
  }
}
@media screen and (max-width: 990px) {
  .ht__reportesScreen-container-table-header,
  .ht__reportesScreen-container-table-content {
    & > div:nth-child(1) {
      width: 64%;
      & > div:nth-child(1) {
        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .all-campanias {
        padding-left: 5px;
        // background-color: red;
        width: 150%;
      }
    }
    & > div:nth-child(2) {
      display: none;
    }
    & > div:nth-child(3) {
      width: 30%;
    }
    & > div:nth-child(4) {
      display: none;
    }
    & > div:nth-child(5) {
      width: 6%;
    }
  }
  .ht__reportesScreen-container-table-nameMobile {
    display: block;
    margin-top: 18px;
    // margin-bottom: 8px;
    // width: 24%;
    p:nth-child(1) {
      // background-color: red;
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 18px !important;
      color: $gris1 !important;
      padding-bottom: 2px !important;
    }
    p:nth-child(2) {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 16px !important;
      color: $azulPrincipal !important;
      padding: 4px 8px !important;
      padding-top: 8px !important;
      background-color: $azulPrincipalClaro !important;
      border-radius: 50px !important;
      width: max-content;
      display: flex;
      align-items: center;
    }
  }
  .ht__reportesScreen-container-table-actionsMobile {
    margin-top: 10px;
    display: block;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $gris1;
      margin-bottom: 7px;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        width: 48%;
        display: flex;
        background-color: $gris5;
        border-radius: 8px;
        justify-content: center;
        align-items: center;
        padding: 8px 0px;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $negro1;
        img {
          margin-right: 12px;
        }
      }
    }
  }
  .all-campanias-show {
    h6 {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 12px !important;
      line-height: 18px !important;
    }
    & > p {
      font-style: normal !important;
      font-weight: normal !important;
      font-size: 12px !important;
      line-height: 18px !important;
    }
  }
}
.btn-generar-mobile {
  display: none;
}
@media screen and (max-width: 910px) {
  .ht__reportesScreen-noReport {
    height: 70.5vh;
  }
}
@media screen and (max-width: 690px) {
  .ht__reportesScreen {
    padding: 26px 13px;
    // height: 80%;
    // background-color: rebeccapurple;
  }
}
@media screen and (max-width: 630px) {
  .ht__reportesScreen-container-table-header,
  .ht__reportesScreen-container-table-content {
    & > div:nth-child(1) {
      width: 60%;
    }
    & > div:nth-child(3) {
      width: 30%;
    }
    & > div:nth-child(5) {
      width: 6%;
    }
  }
  .ht__reportesScreen-container-table-actionsMobile {
    & > div {
      & > div {
        img {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 630px) {
  .ht__reportesScreen-container-header {
    padding: 25px 14px;
  }
  .ht__reportesScreen-container-table-header,
  .ht__reportesScreen-container-table-content {
    & > div:nth-child(1) {
      width: 50%;
      padding-left: 14px;
      .all-campanias {
        // background-color: rebeccapurple;
        width: 140%;
      }
    }
    & > div:nth-child(3) {
      width: 30%;
    }
    & > div:nth-child(5) {
      width: 10%;
    }
  }
  .ht__reportesScreen-container-header-btnGenerar {
    display: none;
  }
  .btn-generar-mobile {
    display: block;
    margin-top: 47px;
    margin-bottom: 18px;
    text-align: center;
    &:hover {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 474px) {
  .ht__reportesScreen-container-header {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    & > p {
      width: 100%;
    }
    & > div {
      padding-top: 18px;
      & > div:nth-child(1) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      width: 100%;
      select {
        width: 75%;
      }
    }
  }
  .ht__reportesScreen-container-table-content {
    & > div:nth-child(3) {
      width: 30%;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .ht__reportesScreen-container-table-header {
    & > div:nth-child(1),
    & > div:nth-child(3) {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }
  }
  .all-campanias-show {
    // background-color: rebeccapurple;
    width: 180% !important;
    // & > div {
    // }
  }
}
