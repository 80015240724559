.ht__interesesSelected {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 33px;
}
.ht__interesesSelected-title {
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: $negro1;
  }
}
.ht__interesesSelected-actions {
  display: flex;
  align-items: center;
  .btn-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $azulSecundario;
    border-radius: 2px;
    padding: 8px 16px;
    margin-left: 12px;
    &:hover {
      //   background-color: $principalOscuro;
      cursor: pointer;
    }
    img {
      margin-right: 6px;
    }
    p {
      margin: 0;
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      font-family: $righteous;
    }
  }
  .btn-csv {
    margin-right: 32px;
    margin-left: 22px;
  }
  .btn-csv-import {
    margin-right: 25px;
  }
  .btn-csv,
  .btn-csv-import {
    font-family: $righteous;
    text-decoration: none;
    position: relative;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $negro2;
    text-transform: uppercase;
    input {
      position: absolute;
      z-index: -1;
      width: 1%;
    }
    &:hover {
      //   background-color: $principalOscuro;
      cursor: pointer;
      color: $azulSecundario;
    }
  }
  .btn-delete {
    padding: 6px 12px;
    border-radius: 4px;

    &:hover {
      //   background-color: $principalFondo;
      cursor: pointer;
      transition: 0.5s;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: $negro1;
      text-transform: uppercase;
    }
  }
}
.ht__interesesSelected-containerIntereses {
  // width: 100%;
  background-color: $blanco;
  // min-height: 120px;
  max-height: 280px;
  // height: 100%;
  padding: 18px;
  overflow-y: scroll;
  margin-top: 13px;
  margin-bottom: 46px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  border: 1px solid #e6ecef;
  &::-webkit-scrollbar {
    width: 6px !important;
    background-color: #fff !important;
  }
  // ::-webkit-scrollbar {
  //   width: 8px;
  // }
  &::-webkit-scrollbar-thumb {
    background-color: $azulSecundario;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }
  & > div {
    display: flex;
    align-items: center;
    background-color: $azulPrincipalClaro;
    margin-bottom: 15px;
    margin-right: 12px;
    padding: 8px 14px;
    // height: max-content;
    p {
      color: $negro1;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 15%;
    }
    img {
      margin-left: 5px;
      width: 16px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__interesesSelected-copy {
  background-color: #e0ffdf !important;
  border: 1px solid #b4fbb3 !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px !important;
  position: absolute;
  right: 0;
  z-index: 1;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    // color: $negro !important;
  }
}
.ht__hashtagSelectd-copy-img {
  display: flex;
  align-items: center;
}

.ht__interesesSelected-error {
  background-color: #ffa86e;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(79, 82, 88, 0.171);
  p {
    color: #fff;
    font-weight: 500;
    margin: 0;
  }
}
.ht__interesesSelected-actionMobile {
  display: none;
}
@media screen and (max-width: 922px) {
  .ht__interesesSelected {
    padding: 10px 16px 0 16px;
  }
  .ht__interesesSelected-containerIntereses {
    margin-left: 16px;
    margin-right: 16px;
  }
}
@media screen and (max-width: 474px) {
  .ht__interesesSelected {
    padding: 10px 16px 0 16px;
    margin-bottom: 13px;
    margin-top: 28px;
    .ht__interesesSelected-title {
      margin-bottom: 0;
      width: 100%;
      // background-color: rebeccapurple;
      p {
        text-align: start;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  .ht__interesesSelected-actions {
    display: none;
  }
  .ht__interesesSelected-actionMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 32px;
    & > div:nth-child(2) {
      display: flex;
    }
  }
  .ht__interesesSelected-containerIntereses {
    margin-bottom: 16px;
    padding: 18px 14px;
    min-height: auto;
    & > div {
      p {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .ht_containerListIntereses-header {
    padding: 0 14px !important;
  }
}

@media all and (min-width: 468px) {
  .ht__interesesSelected {
    flex-direction: row;
    margin-bottom: 12px;
  }
}

@media all and (min-width: 768px) {
}
