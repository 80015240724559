.ht__generoStatistics {
  margin-top: 31px;
}
.ht__generoStatistics-row:first-child {
  margin-bottom: 18px;
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #11214f;
  }
}
.ht__generoStatistics-row {
  display: flex;
  width: 100%;
  margin-bottom: 12px;
  // background-color: aliceblue;
  & > div:nth-child(1) {
    width: 20%;
    // background-color: rebeccapurple;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $negro1;
    // flex: 1;
  }
}
.ht__generoStatistics-row-data {
  display: flex;
  align-items: center;
  // background-color: rebeccapurple;
  width: 80%;
  // justify-content: space-between;
  .ht__generoStatistics-reach,
  .ht__generoStatistics-clicks {
    width: 33%;
    // background-color: azure;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $negro1;
  }
  .ht__generoStatistics-clicks-cpc,
  .ht__generoStatistics-ctr {
    width: 13.5%;
    // background-color: red;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $azulPrincipal;
  }
  .ht__generoStatistics-clicks-cpc {
    text-align: end;
    padding-right: 16px;
  }

  .ht__generoStatistics-reach,
  .ht__generoStatistics-clicks {
    display: flex;
    .ht__ageStatistics-percentage {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: $gris1;
      margin-left: 12px;
    }
  }
}
.ht__generoStatistics-row-female {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $amarillo2;
    margin-right: 10px;
  }
}
.ht__generoStatistics-usd {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: $gris2;
}
.ht__generoStatistics-row-yellow {
  background-color: $amarillo2;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__generoStatistics-color-yellow {
  color: $amarillo;
}
.ht__generoStatistics-row-male {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $azulSecundario;
    color: #fff;
    margin-right: 10px;
  }
}
.ht__generoStatistics-row-blue {
  background-color: $azulSecundario;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
  color: #fff;
}
.ht__generoStatistics-color-blue {
  color: $azulSecundario;
}
.ht__generoStatistics-row-unknown {
  display: flex;
  align-items: center;
  & > div {
    width: 16px;
    height: 6px;
    background-color: $azulPrincipalClaro2;
    margin-right: 10px;
  }
}
.ht__generoStatistics-row-blueLight {
  background-color: $azulPrincipalClaro2;
  text-align: end;
  // width: 75%;
  padding: 2px 4px;
}
.ht__generominwidth {
  min-width: fit-content;
}
