.ht_header {
  background-color: $blanco;
  box-shadow: 0px 2px 0px #e6ecef;
  display: flex;
  justify-content: space-between;
  padding: 18px 69px;
  & div:nth-child(1) {
    width: 50%;
    // display: flex;
    // align-items: center;
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      color: #000;
    }
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $azulSecundario;
}

input:focus + .slider {
  box-shadow: 0 0 1px $azulSecundario;
}

input:checked + .slider:before {
  -webkit-transform: translateX(29px);
  -ms-transform: translateX(29px);
  transform: translateX(29px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.ht_header-containerSwitch {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  width: 65%;
  img {
    margin-top: 5px;
    margin-right: 6px;
    width: 16px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht_header-containerSwitch-title {
  padding-right: 16px;
  text-align: end;
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    color: $negro1;
    font-family: $righteous;
    margin: 0;
  }
  & > p {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    color: $negro2 !important;
  }
}

@media screen and (max-width: 922px) {
  .ht_header {
    padding: 14px 16px 16px 16px;
    box-shadow: none;
    // background-color: #ffffff00 !important;
  }
}
@media screen and (max-width: 700px) {
  .ht_header {
    & div:nth-child(1) {
      width: 100%;
      p {
        font-size: 18px;
      }
    }
  }
}
