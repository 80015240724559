.ht__reporteSpecifications {
  padding: 56px 60px;
}
.ht__reporteSpecifications-description {
  background-color: $blanco;
  padding: 32px;
  border: 1px solid $gris4;
  border-radius: 8px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // align-items: center;
}
.ht__reporteSpecifications-description-left {
  width: 60%;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    font-family: $righteous;
    color: $azulPrincipal;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
  }
  .fecha {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
    span {
      padding: 0;
      background-color: rgba(255, 255, 255, 0);
      font-weight: 500;
    }
  }

  .container-row {
    display: flex;
    align-items: center;

    .cliente {
      margin-top: 24px;
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $gris1;
      }
      .name {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $azulPrincipal;
      }
    }
    .container-ad {
      margin-top: 24px;
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $gris1;
        margin-bottom: 2px;
      }
      .status {
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        color: $azulPrincipal;
        & > div {
          height: 10px;
          width: 10px;
          background-color: #38b249;
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
    .container-reportType {
      margin-top: 24px;
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $gris1;
        margin-bottom: 2px;
      }
      .type {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: $azulPrincipal;
      }
    }
    .container-row-right {
      padding-left: 60px;
      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $gris1;
        margin-bottom: 2px;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 9px;
      }
    }
  }
}

.ht__reporteSpecifications-description-row {
  display: flex;
  margin-top: 32px;
  .campanias {
    width: 50%;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      font-family: $righteous;
      color: $azulPrincipal;
      margin: 0;
      padding-bottom: 14px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $azulPrincipal;
      margin-bottom: 10px;
    }
  }
  .reporte {
    width: 50%;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 29px;
      font-family: $righteous;
      color: $azulPrincipal;
      margin: 0;
      padding-bottom: 14px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $azulPrincipal;
    }
    .reporte-tags {
      margin-top: 15px;
      display: flex;
      align-items: center;
      //   justify-content: space-between;
      flex-wrap: wrap;
      div {
        background-color: $amarillo4;
        width: max-content;
        padding: 8px 14px;
        margin-right: 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: $negro1;
      }
    }
  }
}
.ht__reporteSpecifications-reporte {
  background-color: $blanco;
  padding: 56px 55px;
  // text-align: center;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    font-family: $righteous;
    color: $azulPrincipal;
    margin-bottom: 36px;
  }
}
.ht__reporteSpecifications-reporte-cards {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
    border: 1px solid $gris4;
    padding: 24px;
    border-radius: 4px;
    width: 19%;
    margin-right: 12px;
    margin-bottom: 30px;
    .icon {
      background-color: $azulPrincipalClaro;
      margin-right: 19px;
      width: 40px;
      height: 40px;
      //   padding: 15px 19px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & > div:nth-child(2) {
      & > p:nth-child(1) {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 21px;
        color: $gris1;
      }
      p {
        font-style: normal;
        text-align: start;
        font-weight: 500;
        font-size: 24px;
        line-height: 31px;
        color: $azulPrincipal;
        span {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: $azulPrincipal;
        }
      }
    }
  }
}
.ht__reporteSpecifications-paises {
  background-color: $blanco;
  h4 {
    font-family: $righteous;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 27px;
    color: $azulPrincipal;
    margin-bottom: 32px;
  }
  .ht__cardsReport-header {
    margin-left: 55px;
    margin-bottom: 23px;
  }
}

.ht__cardsReport-header-distribution {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 68px;
}
.ht__cardsReport-header-distribution-first {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  font-family: $righteous;
  color: $azulPrincipal;
  & > div {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $azulPrincipalClaro;
    margin-right: 25px;
  }
}
.ht__cardsReport-headerdropbox {
  // background-color: aliceblue;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: column;
  // justify-content: flex-end;
  align-items: flex-end;
}
.ht__cardsReport-headerdropbox-select {
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 80%;
  border: 1px solid $gris3;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: $negro2;
}
.ht__cardsReport-headerdropbox-options {
  background-color: #fff;
  position: absolute;
  border: 1px solid #e6ecef;
  border-radius: 8px;
  top: 100%;
  width: 90%;
  margin-top: 3px;
  display: none;
  & > div:first-child {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $gris1;
    padding: 8px 0 8px 15px;
  }
  .options-dropbox {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $negro1;
    padding: 8px 0 8px 15px;
    &:hover {
      cursor: pointer;
      background-color: $azulPrincipalClaro;
    }
    input {
      margin-right: 7px;
    }
  }
}
.ht__cardsReport-open {
  display: block;
}

.ht__reporteSpecifications-description-right {
  width: 40%;
  // background-color: red;
  .container-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > div {
      &:hover {
        cursor: pointer;
      }
    }
    .download-btn {
      background-color: $azulSecundario;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 18px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      margin-right: 18px;
      img {
        margin-left: 10px;
      }
    }
  }
  .containerPresupuesto {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 47px;
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $gris1;
    }
    .value {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $azulPrincipal;
    }
  }
  .containerImporte {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 24px;
    .title {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      color: $gris1;
    }
    .value {
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: $azulPrincipal;
    }
  }
}
.ht__reportdelete {
  .ht__modalComponent-children {
    height: auto !important;
  }
}

@media screen and (max-width: 474px) {
  .ht__reporteSpecifications-description {
    display: none;
  }
  .ht__reporteSpecifications {
    padding: 26px 13px;
  }
  .ht__reporteSpecifications-reporte {
    padding: 18px 14px;
  }
  .ht__reporteSpecifications-reporte-cards {
    flex-direction: column;
    & > div {
      width: auto;
    }
  }
  .ht__engagementComponent-container {
    // background-color: rebeccapurple;
    width: 45% !important;
    & > div {
      width: 120px !important;
      height: 120px !important;
    }
  }
}

@media screen and (max-width: 1642px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: 25%;
    }
  }
}
@media screen and (max-width: 1280px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: 40%;
    }
  }
}
@media screen and (max-width: 1133px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: 38%;
    }
  }
}
@media screen and (max-width: 1001px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: 36%;
    }
  }
}
@media screen and (max-width: 992px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: 100%;
    }
  }
}
@media screen and (max-width: 463px) {
  .ht__reporteSpecifications-reporte-cards {
    & > div {
      width: auto;
    }
  }
}
