.ht__planMedidaModal {
  margin: 32px;
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
    margin-bottom: 20px;
  }
  .title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 18px;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 35px;
      color: #000;
      font-family: $righteous;
    }
    img {
      position: absolute;
      top: 0;
      right: 0;
      width: 23px;
    }
  }
}
.ht__planMedidaModal-msg {
  border-radius: 8px;
  background-color: $amarillo4;
  padding: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: $negro1;
  margin-bottom: 18px;
}
.ht__planMedidaModal-btnSolicitar {
  background-color: $azulSecundario;
  border-radius: 2px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-family: $righteous;
  color: #fff;
  padding: 10px 0;
}
