.ht__libreriaScreen-rowBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 60px 0 60px;
  //   background-color: rebeccapurple;
  & > div:nth-child(1) {
    position: relative;
    width: 20%;
    // background-color: rebeccapurple;
    img {
      position: absolute;
      top: 32%;
      left: 12px;
    }
  }
  input {
    background-color: rgba(255, 255, 255, 0);
    border: 1px solid $gris3;
    border-radius: 4px;
    padding: 12.5px 12px 12.5px 38px;
    width: 80%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro2;
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $negro2;
    }
  }
}
.ht__libreriaScreen-rowBetween-filtro {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $gris3;
  border-radius: 4px;
  padding: 12px 14px;
  &:hover {
    cursor: pointer;
  }
  .icon {
    margin-right: 14px;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $azulPrincipal;
  }
}
.ht__libreriaScreen-containerCards {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 24px 60px 60px 60px;
}

@media screen and (max-width: 1124px) {
  .ht__libreriaScreen-rowBetween {
    & > div:nth-child(1) {
      width: 35%;
    }
  }
}
@media screen and (max-width: 800px) {
  .ht__libreriaScreen-containerCards {
    padding: 30px 17px;
  }
  .ht__libreriaScreen-rowBetween {
    padding: 33px 17px 24px 17px;
    & > div:nth-child(1) {
      width: 45%;
    }
  }
}
@media screen and (max-width: 550px) {
  .ht__libreriaScreen-containerCards {
    padding: 16px 17px;
  }
  .ht__libreriaScreen-rowBetween {
    padding-bottom: 0px;
    & > div:nth-child(1) {
      width: 70%;
      img {
        top: 30%;
      }
      input {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 12px;
        line-height: 14px;
      }
      input::placeholder {
        font-size: 12px;
        line-height: 14px;
      }
    }
    & > div:nth-child(2) {
      width: 20%;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .ht__libreriaScreen-rowBetween-filtro {
    .icon {
      margin-right: 8px;
      img {
        width: 19px;
      }
    }
    & > div {
      p {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .ht__libreriaScreen-rowBetween {
    padding-bottom: 0px;
    & > div:nth-child(1) {
      width: 60%;
    }
    & > div:nth-child(2) {
      width: auto;
    }
  }
}
@media screen and (max-width: 350px) {
  .ht__libreriaScreen-rowBetween {
    padding-bottom: 0px;
    & > div:nth-child(1) {
      width: 52%;
    }
  }
}
