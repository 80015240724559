.ht__interesesVacio {
  width: 80%;
  //   background-color: rgb(119, 119, 119);
  margin: 130px auto 0 auto;
  //   height: 65vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    font-family: $righteous;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: $azulPrincipal;
    margin: 0;
    padding-bottom: 8px;
  }
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $gris1;
    margin-bottom: 48px;
  }
}
.ht__iconhelp:hover {
  cursor: pointer;
}
.ht__intereses-noFind {
  width: 100%;
  margin-top: 24px;
  background-color: $blanco;
  border: 1px solid #e6ecef;
  border-radius: 8px;
  height: 422px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $azulPrincipal;
    margin-bottom: 9px;
  }
  & > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $gris1;
    margin-bottom: 55px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      margin-bottom: 18px;
    }
    p {
      margin-left: 6px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $gris1;
    }
  }
}
.ht__interesesVacio-icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 90%;
  margin-top: 88px;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      background-color: $gris4;
      border-radius: 100%;
      padding: 22px 26px;
      &:hover {
        cursor: pointer;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gris1;
      margin-top: 10px;
    }
  }
}
.ht__interesesVacio-importBtn {
  position: relative;
  #import-csv {
    position: absolute;
    top: 0;
    z-index: -1;
  }
}
@media screen and (max-width: 922px) {
  .ht__interesesVacio {
    .ht_inputFindInteres {
      width: 100%;
    }
  }
  .ht__interesesVacio-icons {
    & > div {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 474px) {
  .ht__interesesVacio {
    h3 {
      font-size: 18px;
      line-height: 26px;
    }
    p {
      font-size: 14px;
      line-height: 16px;
    }
  }
  .ht__interesesVacio-icons {
    margin-bottom: 50px;
    width: 100%;
    & > div {
      width: 31%;
      // background-color: rebeccapurple;
      & > div {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      p {
        font-size: 12px;
        line-height: 14px;
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 414px) {
  .ht__interesesVacio-icons {
    margin-bottom: 90px;
  }
}
