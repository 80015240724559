.ht__modalFBAccountSynced-header {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 16px;
}
.ht__modalFBAccountSynced-header-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 23px;
  &:hover {
    cursor: pointer;
  }
}
.ht__modalFBAccountSynced-header-profileImg {
  position: relative;
  & > img {
    position: absolute;
    bottom: 2px;
    right: -5px;
    width: 22px;
  }
  .container-img {
    width: 55px;
    height: 55px;
    img {
      border-radius: 50%;
      width: 100%;
      height: 55px;
      object-fit: cover;
    }
  }
}

.ht__modalFBAccountSynced-name {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: $negro2;
  margin-bottom: 18px;
}
.ht__modalFBAccountSynced-message {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  color: $negro1;
  font-family: $righteous;
}
