.ht__containerInfoDescription {
  width: 100%;
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 32px;
    color: $azulPrincipal;
    margin-bottom: 10px;
    font-family: $righteous;
  }
}
.ht__containerInfoDescription-row {
  display: flex;
  width: 100%;
}
.ht__containerInfoDescription-left {
  width: 60%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
    margin-bottom: 24px;
    width: 85%;
  }
}
.ht__singleAudiencia-containerInfo-containerBtn {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  .btnAgregar {
    // width: 34%;
    padding: 10px 20px;
    border: 1px solid #1772c2;
    border-radius: 2px;
    background-color: $blanco;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    font-family: $righteous;
    color: $azulSecundario;
    text-align: center;
    margin-right: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .btn-copiar {
    // width: 60%;
    background: $azulSecundario;
    border-radius: 2px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    font-family: $righteous;
    color: $blanco;
    text-align: center;
    padding: 10px 20px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__containerInfoDescription-right {
  width: 30%;
  border-left: 1px solid $gris4;
  padding-left: 34px;
}
.ht__containerInfoDescription-right-container {
  margin-bottom: 22px;
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: $gris1;
    margin-bottom: 4px;
  }
  .numero {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    color: $azulPrincipal;
  }
}
