.ht__filtroComponent-opacity {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  //   z-index: 2;
}
.ht__filtroComponent {
  position: fixed;
  height: 90vh;
  background-color: $blanco;
  width: 18%;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px;
  overflow-y: scroll;

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-aplicar {
      background-color: $azulSecundario;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      color: $blanco;
      font-family: $righteous;
      padding: 10px 20px;
      margin-left: 38px;
      border-radius: 2px;
      &:hover {
        cursor: pointer;
      }
    }
    .btn-cancelar {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 23px;
      font-family: $righteous;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.ht__filtroComponent-header {
  position: relative;
  h4 {
    font-family: $righteous;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 35px;
    color: #000;
    margin: 0;
    margin-bottom: 2px;
    display: flex;
    align-items: center;
    .container-filtro-filtersNum {
      margin-left: 12px !important;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $gris1;
  }

  img {
    right: 0;
    top: 0;
    position: absolute;
    width: 24px;
    &:hover {
      cursor: pointer;
    }
  }
}
.ht__filtroComponent-audiencia {
  margin-top: 43px;
  margin-bottom: 32px;

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    font-family: $righteous;
    color: #000;
  }
}
.ht__filtroComponent-audiencia-inputs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
  & > div {
    width: 38%;
  }
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    font-family: $righteous;
    color: $gris1;
  }
  input {
    width: 100%;
    border: 1px solid #d9dcde;
    border-radius: 2px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
    margin-top: 8px;
  }
}
.ht__filtroComponent-titleCategoria {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 29px;
  color: #000;
  font-family: $righteous;
  margin-bottom: 12px;
}
.ht__filtroComponent-containerCategorias {
  .ht__filtroComponent-containerCategorias-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $azulPrincipalClaro;
    border-radius: 2px;
    padding: 10px 12px;
    margin-top: 12px;
    &:hover {
      cursor: pointer;
    }
    & > div {
      display: flex;
      align-items: center;
      input {
        margin-right: 10px;
        border: 1px solid #d9dcde;
        border-radius: 2px;
        background-color: $blanco;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: $negro1;
      }
    }
  }
}
.ht__filtroComponent-containerCategorias-submenu {
  & > div {
    display: flex;
    align-items: center;
    margin-left: 35px;
    // padding: 10px 12px;
    margin-top: 22px;
    margin-bottom: 24px;
    input {
      margin-right: 10px;
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #000;
    }
  }
}

.ht__filtroComponent-containerNichos {
  margin-top: 40px;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    p {
      font-family: $righteous;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 29px;
      color: #000;
      margin-right: 9px;
    }
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }
  select {
    width: 100%;
    border: 1px solid $gris3;
    border-radius: 2px;
    padding: 10px 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: $negro1;
  }
}

@media screen and (max-width: 1050px) {
  .ht__filtroComponent {
    width: 30%;
  }
}
@media screen and (max-width: 750px) {
  .ht__filtroComponent {
    width: 35%;
  }
}
@media screen and (max-width: 650px) {
  .ht__filtroComponent {
    width: 86%;
  }
}
@media screen and (max-width: 550px) {
  .ht__filtroComponent {
    width: 94%;
    height: 95vh;
  }
  .ht__filtroComponent {
    padding: 32px 16px;
  }
}
@media screen and (max-width: 474px) {
  .ht__filtroComponent {
    width: 93%;
    height: 93vh;
  }
}
